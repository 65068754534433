import { Component, OnInit } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss']
})
export class SuccessMessageComponent implements OnInit {

  public DomainName: string;
  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.DomainName = document.location.host;
  }

  
  public onCancel(): void {
    this.modalRef.hide();
  }

}
