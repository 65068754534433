import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubSink } from 'subsink';
import * as moment from 'moment';
import 'moment-timezone';
import { Location } from '@angular/common';
import { MessageFor, AppStorage, PaymentType, StreamType, PaymentMode, PaymentString } from '../providers/enum';
import { APP_ID, dnpPaySuccessAction, stripeAPIPubKey, dnpTicketGenAction, STREAM_GUID, paypalClientID, USAEventTimeZone, EuropeEventTimeZone, stripeAPIToken } from '../providers/constant';
import { StorageService } from '../shared/services/storage.service';
import { DonatePerViewService } from './services/donate-per-view.service';
import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { LiveVideoPlayerComponent } from '../live-video-player/live-video-player.component';
import { StreamDetailsService } from '../shared/services/stream-details.service';

@Component({
  selector: 'app-donate-per-view-modal',
  templateUrl: './donate-per-view-modal.component.html',
  styleUrls: ['./donate-per-view-modal.component.scss']
})
export class DonatePerViewModalComponent implements OnInit {

  //#region variables

  handler: any = null;
  public payFormGroup: FormGroup;
  public errorMessage: string;
  public isOtherOptionPaymentSelected: boolean = false;
  public selectedValue: string;
  public paymentSuccess = false;
  public payMessage: any;
  public payAmount = 0;
  public showLoading: boolean = false;

  public userData: any;
  public streamDetails: any;
  public isCheckValidation = false;
  public paypalPayAmount = 1;
  public payPalConfig?: IPayPalConfig;
  public logObject: any;
  public payObj: any;
  public DomainName: string;
  public currentTimeZone: string;
  public donationAmount = [];
  public streamMultipleDates = [];
  public minimumAmount: string;
  public paymentType: PaymentType;
  public PaymentType = PaymentType;
  public streamType: StreamType;
  public StreamType = StreamType;
  public selectedDates = [];
  public stripeAPITokenKeyLS: string;
  public stripeAPIPubKeyLS: string;
  public localServerFlag: string;
  public hosturlPay: string;
  public paymentModeV: string;
  public characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  private subs = new SubSink();
  public stripeProdIDLT: string;
  public customAmountText: string;
  //#endregion

  //#region life-cycle hook

  constructor(private formBuilder: FormBuilder,
    private storageService: StorageService,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private location: Location,
    private streamDetailsService: StreamDetailsService,
    private donatePerViewService: DonatePerViewService) { }

  ngOnInit() {
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    let eventData = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(eventData);
    //console.log("stream detail 0", this.streamDetails[0]);
    this.currentTimeZone = this.streamDetails[0].stream_timezone;
    this.streamType = this.streamDetails[0].stream_type;
    this.paymentModeV = this.streamDetails[0].stream_payment_options.payment_mode;
    this.paymentType = this.streamDetails[0].stream_payment_options.payment_type;
    this.donationAmount = this.streamDetails[0].stream_payment_options.donation_amount;
    this.minimumAmount = this.streamDetails[0].stream_payment_options.minimum_donation_amount;
    this.customAmountText = this.streamDetails[0].stream_payment_options.other_amount_text;
    
    this.localServerFlag = this.streamDetails[0].stream_payment_options.local_server_flag;
    let result2 = this.streamDetailsService.trackUserAccessPayment(this.streamDetails[0].app_code, this.userData.userCode).toPromise();
    if (this.streamType === StreamType.Multiple) {
      //console.log('this.streamType: ', this.streamType);
      this.streamMultipleDates = this.streamDetails[0].stream_multiple_dates;
    }
    
    //console.log("streamd=", this.streamDetails);
    //console.log("paymodV=", this.paymentModeV);
    if(this.paymentModeV === 'L') {
      //console.log("LIVE");
      this.stripeAPIPubKeyLS = this.streamDetails[0].stream_payment_options.stripe_API_pubkey_live;
      this.stripeAPITokenKeyLS = this.streamDetails[0].stream_payment_options.stripe_API_token_live;
      this.stripeProdIDLT = this.streamDetails[0].stream_payment_options.product_id_live;
    }
    else if(this.paymentModeV === 'T') {
      //console.log("Test");
      //console.log("p=", this.streamDetails[0].stream_payment_options.stripe_API_pubkey_test);
      this.stripeAPIPubKeyLS = this.streamDetails[0].stream_payment_options.stripe_API_pubkey_test;
      this.stripeAPITokenKeyLS = this.streamDetails[0].stream_payment_options.stripe_API_token_test;
      this.stripeProdIDLT = this.streamDetails[0].stream_payment_options.product_id_test;
    }

    if(this.localServerFlag === 'Y')
    {
      this.hosturlPay = this.streamDetails[0].stream_payment_options.local_server_url;
    }
    else {
      this.hosturlPay = this.streamDetails[0].stream_payment_options.live_server_url;
    }

  //  this.stripeAPIPubKeyLS = this.streamDetails[0].stream_payment_options.stripe_API_pubkey_test;
   // this.stripeAPITokenKeyLS = this.streamDetails[0].stream_payment_options.stripe_API_token_test;

    //console.log("key=", this.stripeAPIPubKeyLS);
    // this.DomainName = document.location.host;
    // if (this.DomainName === "econcert.chords2cure.tv" || this.DomainName === "localhost:4200") {
    //   this.currentTimeZone = EuropeEventTimeZone;
    // }
    // if (this.DomainName === "concert.chords2cure.tv" || this.DomainName === "testconcert.chords2cure.tv") {
    //   this.currentTimeZone = USAEventTimeZone;
    // }

    //console.log("const test:", stripeAPIPubKeyTest);
    this.loadStripe();
    this.createPayFormGroup();
    this.getRegisterMessage();
    if (this.paymentType === PaymentType.Donation) {
      this.selectedValue = this.minimumAmount;
    } else if (this.paymentType === PaymentType.Fixed) {
      this.selectedValue = this.streamDetails[0].stream_payment_options.fixed_ticket_amount;
    }
    this.initConfig(this.selectedValue);


    this.payFormGroup.controls.inputEmail.markAsTouched;
    // setTimeout(() => this.payFormGroup.controls.inputEmail.se;
  }

  private showSuccess = false;
  public paypalPay() {

    // alert(this.selectedValue);
  }
  private initConfig(payAMOUNT): void {
    let self = this;
    var emailG = this.payFormGroup.controls.inputEmail.value;
    var invalidEmail = this.payControls?.inputEmail.invalid;
    var nameG = this.payFormGroup.controls.inputName.value
    if (emailG == "" || nameG == null || invalidEmail == true) {
      this.errorMessage = "Please enter Email Address OR Name to get Payment Options.";
    }
    else {
      this.payPalConfig = {
        currency: 'USD',
        clientId: paypalClientID,
        createOrderOnClient: (data) => <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: payAMOUNT,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: payAMOUNT
                  }
                }
              },
              items: [
                {
                  name: 'Enterprise Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'USD',
                    value: payAMOUNT,
                  },
                }
              ]
            }
          ]
        },
        advanced: {
          commit: 'true'
        },
        style: {
          label: 'paypal',
          layout: 'vertical'
        },
        onApprove: (data, actions) => {
        //  console.log('onApprove - transaction was approved, but not authorized', data, actions);
          actions.order.get().then(details => {
           // console.log('onApprove - you can get full order details inside onApprove: ', details);
            // alert(details.id);
          //  console.log('Paypal Order Id', details.id);
            var paypalToken = "PAYPAL:" + details.id;
            // self.sendGiftTicketReq(payAMOUNT, paypalToken);
          });
        },
        onClientAuthorization: (data) => {
         // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
          this.showSuccess = true;
        },
        onCancel: (data, actions) => {
        //  console.log('OnCancel', data, actions);
        },
        onError: err => {
         // console.log('OnError', err);
        },
        onClick: (data, actions) => {
          //alert("dddd");
         // console.log('onClick', data, actions);
        },
      };

    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }


  //#endregion

  //#region private methods

  private getRegisterMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.payMessage = messageList[MessageFor.DonatePerViewModal];
      });
  }

  private createPayFormGroup(): void {
    if (this.userData) {
      this.payFormGroup = this.formBuilder.group({
        inputName: [this.userData.name, Validators.required],
        inputEmail: [this.userData.email, [Validators.required, CommonUtilsService.checkEmail]],
        selectedDates: [null, Validators.required]
      }, {
        validator: [
          CommonUtilsService.checkEmail('inputEmail', true)
        ]
      });
      this.errorMessage = '';
    } else {
      this.payFormGroup = this.formBuilder.group({
        inputName: [null, Validators.required],
        inputEmail: [null, [Validators.required, CommonUtilsService.checkEmail]],
        selectedDates: [null, Validators.required]
      }, {
        validator: [
          CommonUtilsService.checkEmail('inputEmail', true)
        ]
      });
    }
  }

  public pay5(price) {

    var formDataG = new FormData();
    formDataG.append('stripe_key', 'sk_test_Lr0VtmNnhemHch6IUvauT4dI')
    formDataG.append('payment_method_types', 'card')
    formDataG.append('product', 'prod_MGxp6FssXuritG')
    formDataG.append('unit_amount', '1500')
    formDataG.append('currency', 'usd')
    formDataG.append('success_url', 'http://localhost:4200/?domain=dancestar&url=success')
    formDataG.append('cancel_url', 'http://localhost:4200/?domain=dancestar&url=cancel')

    this.subs.sink = this.donatePerViewService.purchaseBundleByUser(formDataG).subscribe(
      (response: any) => {
        //console.log('response email: ', response);
        if (response.url) {
          window.open(response.url, "_self");
        }
        // if (response.status === 0) {
        //   this.errorMessage =
        //     "Oops something went wrong please try again later.";
        // } else {
        //   this.errorMessage = response.msg;
        // }
        this.showLoading = false;
      },
      (error) => {
        //console.log('error: ', error);
        this.errorMessage = "oops something went wrong please try again later.";
        this.showLoading = false;
      }
    );
  }
  private pay(amount) {

//alert(amount);

    var emailG = this.payFormGroup.controls.inputEmail.value;
    let AppCode = this.streamDetails[0].app_code;
    let StreamGuid = this.streamDetails[0].stream_guid;
    let MenuGuid = this.streamDetails[0].menu_guid;
    let PaymentCurrency = this.streamDetails[0].stream_payment_options.payment_currency;
    let PaymentDescription = PaymentString.StripeMsg + this.streamDetails[0].stream_title;
    let UserCode = this.userData.userCode;
    let stripeAPITOKENkey = this.stripeAPITokenKeyLS;
    let finalAmount = amount * 100;
    let TicketDateID = this.payFormGroup.controls.selectedDates.value;//this.selectedDates;

    
  
    //alert(TicketDate);
    if (emailG == null) {
      this.errorMessage = "Please Enter Email Address.";
      // alert(errMsg);
      this.paymentSuccess = false;

    }
    else {

     // console.log('amount: ', amount);

      let self = this;
      /*
      var handler = (<any>window).StripeCheckout.configure({
        key: this.stripeAPIPubKeyLS,
        locale: 'auto',
        token: async function (token: any) {
          //console.log('token: ', token);
          self.showLoading = true;
          // You can access the token ID with `token.id`.
          // Get the token ID to your server-side code for use.
          if (token.id) {
            self.logObject = {
              name: self.payFormGroup.controls.inputName.value,
              email: emailG,
              amount: amount,
              token: token.id,
            }
            var stripeToken = "STRIPE:" + token.id;
          //  console.log("token---", token.id);
            let payObj = {
              email: emailG,
              amount: amount,
              token: token.id,
              stripeAPITokenKey: stripeAPITOKENkey
              
            }
            

           

            var formDataG = new FormData();
            formDataG.append('email', emailG);
            formDataG.append('amount',  finalAmount.toString());
            formDataG.append('token', token.id);
           // formDataG.append('stripeAPITokenKey', stripeAPITOKENkey);
            formDataG.append('appID', AppCode);
            formDataG.append('streamGuid', StreamGuid);
            formDataG.append('menuGuid', MenuGuid);
            formDataG.append('paymentCurrency', PaymentCurrency);
            formDataG.append('paymentDescription', PaymentDescription);
            formDataG.append('userCode', UserCode);
            formDataG.append('streamDateID', TicketDateID);
           
           // console.log("pay=", payObj);
           // console.log("em=", emailG);
           // console.log("formG", formDataG);
           // alert(TicketDate);
            self.logObject["status"] = "Before API call";
          //  let log = await self.donatePerViewService.generateLogs(self.logObject).toPromise();
            self.subs.sink = self.donatePerViewService.createStripeCharges(formDataG)
            .subscribe(
                async (response: any) => {
               //  console.log("res pay=", response);
                  if (response.status === "succeeded") {
                    self.logObject["status"] = "success";
                    //let log = await self.donatePerViewService.generateLogs(self.logObject).toPromise();
                    self.sendGiftTicketReq(amount, stripeToken);
                    /*
                    let startTime = moment.tz(self.streamDetails.eventStartDateTime, self.streamDetails.eventTimeZone).format();
                    let duration = moment.duration(moment(startTime).diff(moment(new Date())));
                    let days = duration.days();
                    let hours = duration.hours();
                    let min = duration.minutes();
                    if (days <= 0 && hours <= 0 && min <= 14) {
                      setTimeout(() => {
                        self.showLoading = false;
                        self.onCancel();
                        self.modalRef = self.modalService.show(
                          LiveVideoPlayerComponent,
                          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullWidth modal-lg' })
                        );
                      }, 1000);
                    }*/
 /**                  } else {
                    self.errorMessage = response.errMsg;
                    self.paymentSuccess = true;
                    self.showLoading = false;
                    self.logObject["status"] = "failed";
                    //let log = await self.donatePerViewService.generateLogs(self.logObject).toPromise();
                  }
                }, async (error) => {
                 // console.log('errord: ', error);
                  self.errorMessage = "Error occurred during the Payment, Please Try Again.";
                  self.paymentSuccess = true;
                  self.showLoading = false;
                  self.logObject["status"] = "API error";
                  //let log = await self.donatePerViewService.generateLogs(self.logObject).toPromise();
                });

                
          }
        }
      });
*/

     // let fullHost = "http://"+document.location.host  + this.location.path();
      let fullHost = this.hosturlPay;//"http://localhost:4200/app?title=childrenofworld&domain=letmehelp";  /// LOCAL
   //   let fullHost = "https://letmehelp.swigit.com/app/childrenofworld";   /// LIVE 
      //let parts = fullHost.split('.');
     let passURLSucc = "";
     let passURLCanc = "";
     var randStr = this.generateString(15);
      if(this.localServerFlag == "Y")
      {
        passURLSucc = fullHost + '&url=success&payCode='+randStr;
        passURLCanc = fullHost + '&url=cancel';
      }
      else
      {
        passURLSucc = fullHost + '?url=success&payCode='+randStr;
        passURLCanc = fullHost + '?url=cancel';
      }

       // fullHost = fullHost + "&url=";
      
       let amountn = (amount * 100).toString();
      //console.log("ful=", fullHost);
      //console.log("amt=", amountn); 
      var formDataG = new FormData();
      formDataG.append('stripe_key', stripeAPITOKENkey);    
      /// test: sk_test_Lr0VtmNnhemHch6IUvauT4dI
      /// live: sk_live_uLbqsCBkVFeFhgDEXslgr3w9
      formDataG.append('payment_method_types', 'card');
      formDataG.append('product', this.stripeProdIDLT);   
      /// test: prod_MGxp6FssXuritG
      /// live: prod_MGxcETyYXyh3DL
      formDataG.append('unit_amount', amountn);
      formDataG.append('currency', 'usd');
      formDataG.append('success_url', passURLSucc);
      formDataG.append('cancel_url', passURLCanc);
      //console.log("st ob", formDataG);

     // const formDataObj = {};
      //formDataG.forEach((value, key) => (formDataObj[key] = value));

      
      
      this.subs.sink = this.donatePerViewService.purchaseBundleByUser(formDataG).subscribe(
        (response: any) => {
          //console.log('response email: ', response);
          
          if (response.url) {
            var formDataObj = [
              ['stripe_key', 'sk_test_Lr0VtmNnhemHch6IUvauT4dI'],
              ['payment_method_types', 'card'],
              ['product', 'prod_MGxp6FssXuritG'],
              ['unit_amount', amountn],
              ['streamGuid', StreamGuid],
              ['appCId', AppCode],
              ['currency', 'usd'],
              ['useremail', emailG],
              ['payCodeVerify', randStr]
            ];
            
            //alphas = ["1", "2", "3", "4"];
            this.storageService.set(AppStorage.appStripeData, formDataObj, true);

            window.open(response.url, "_self");
          }
         

          
                   // self.logObject["status"] = "success";
                    //let log = await self.donatePerViewService.generateLogs(self.logObject).toPromise();
                  //  self.sendGiftTicketReq(amount, response);
                    
                    let startTime = moment.tz(self.streamDetails.eventStartDateTime, self.streamDetails.eventTimeZone).format();
                    let duration = moment.duration(moment(startTime).diff(moment(new Date())));
                    let days = duration.days();
                    let hours = duration.hours();
                    let min = duration.minutes();
                    if (days <= 0 && hours <= 0 && min <= 14) {
                      setTimeout(() => {
                        self.showLoading = false;
                        self.onCancel();
                        self.modalRef = self.modalService.show(
                          LiveVideoPlayerComponent,
                          Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullWidth modal-lg' })
                        );
                      }, 1000);
                    }
                  
               

                
         



          this.showLoading = false;
        },
        (error) => {
          //console.log('error: ', error);
          this.errorMessage = "oops something went wrong please try again later.";
          this.showLoading = false;
        }
      );

    }

    /*
    handler.open({
      name: 'Donate To Watch',
      description: 'Enter your Credit Card details',
      amount: amount * 100
    });
    */
  }

  

public generateString(length) {
    let result = '';
    const charactersLength = this.characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}


  public emailChg(value) {
    if (value == "") {
      this.errorMessage = "Please enter Email Address OR Name to get Payment Options.";
    }
    else {
      this.errorMessage = "";
      this.initConfig(this.selectedValue);
    }
  }
  private loadStripe() {

    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: stripeAPIPubKey,
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            // console.log(token)
           // alert('Payment Success!!');
          }
        });
      }

      window.document.body.appendChild(s);
    }
  }

  private sendGiftTicketReq(amount, payResult) {
    var nameG = this.payFormGroup.controls.inputName.value + "(DTW-" + this.selectedValue + ")";
    var emailG = this.payFormGroup.controls.inputEmail.value;
    var formDataG = new FormData();
    formDataG.append('appId', this.streamDetails[0].app_code);
    formDataG.append('streamGuid', this.streamDetails[0].stream_guid);
    formDataG.append('postAction', dnpPaySuccessAction);
    formDataG.append('userCode', this.userData.userCode);
    formDataG.append('amount', amount);
    formDataG.append('buyInformation', payResult);
    var errMsg = "";
    //console.log('formG=', formDataG);
    this.subs.sink = this.donatePerViewService.dpvTicketGen(formDataG)
      .subscribe(
        (response: any) => {
          //console.log('response: ', response);
          if (response.status) {
            errMsg = response.msg;
            this.errorMessage = errMsg;
            this.paymentSuccess = true;
            this.showLoading = false;
          } else {
            errMsg = response.msg;
            this.errorMessage = errMsg;
            this.paymentSuccess = false;
            this.showLoading = false;
          }
        },
        (error) => {
         // console.log('error: ', error);
          this.errorMessage = errMsg;
          this.showLoading = false;
        }
      );
  }


  private sendPaymentInfo(amount, token) {

    var formData = new FormData();
    formData.append('appId', this.streamDetails[0].app_code);
    formData.append('streamGuid', this.streamDetails[0].stream_guid);
    formData.append('postAction', dnpTicketGenAction)
    formData.append('userCode', this.userData.userCode)
    formData.append('amount', amount)
    formData.append('buyInformation', token.id)

    var errMsg = "";

    this.subs.sink = this.donatePerViewService.dpvPayment(formData)
      .subscribe(
        (response: any) => {
          //console.log('response: ', response);
          if (response.status) {
            errMsg = response.msg;
            this.errorMessage = errMsg;
            this.paymentSuccess = true;
          } else {
            errMsg = response.msg;
            this.errorMessage = errMsg;
            this.paymentSuccess = false;
          }
        },
        (error) => {
          //console.log('error: ', error);
          this.errorMessage = errMsg;
        }
      );
  }

  //#endregion


  //#region public methods

  public get payControls() { return this.payFormGroup.controls; }

  public finalpay() {
    if (this.isOtherOptionPaymentSelected && this.selectedValue === '0') {
      this.errorMessage = "Please enter Donation Amount more than 0.";
    } else {
      this.pay(this.selectedValue);
    }
  }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }


  public setRadioButton(value: string): void {
    if (value === '0') {
      this.isOtherOptionPaymentSelected = true;
      this.selectedValue = '0';
      var otherAmtG = "";

      if (document.getElementById("txtotherAmount"))
        otherAmtG = document.getElementById("txtotherAmount").nodeValue;

      if (otherAmtG == null || otherAmtG == "") {
        this.errorMessage = "Please enter Donation Amount to get Payment Options.";
      }

    } else {
      this.isOtherOptionPaymentSelected = false;
      this.selectedValue = value;
      this.initConfig(value);
      this.paypalPayAmount = +value;
      this.errorMessage = "";
    }
  }

  public onChnageInput(value: any): void {
    if (value == null || value == "") {
      this.errorMessage = "Please enter Donation Amount to get Payment Options.";
    } else if (parseInt(value) < parseInt(this.minimumAmount) ) {
      this.errorMessage =  `Please enter Donation Amount greater than or equal to $${this.minimumAmount}`;
    } else {
      this.errorMessage = "";
      this.selectedValue = value;
      this.initConfig(value);
      this.paypalPayAmount = +value;
    }
  }
  //#endregion
}
