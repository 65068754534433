import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubSink } from 'subsink';
import * as moment from 'moment';
import 'moment-timezone';
import { Location } from '@angular/common';

import { LoginComponent } from '../../../login/login.component';
import { LoginService } from '../../../login/login.service';
import { RegisterComponent } from '../../../register/register.component';
import { SupportComponent } from '../../../support/support.component';
import { AppStorage, StreamType } from '../../../providers/enum';
import { StorageService } from '../../services/storage.service';
import { StreamDetailsService } from '../../services/stream-details.service';
import { DonatePerViewService } from '../../../donate-per-view-modal/services/donate-per-view.service';
import { ActivatedRoute, Router } from '@angular/router';
import { decode } from 'html-entities';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  //#region variables

  @Input() result: any;

  public modalRef: BsModalRef;
  public userData: any;
  public streamDetails: any;
  public streamDescFull: any;
  public textBelowPoster: any;
  public streamDetailsFull: any;
  public isUserLogin: boolean = false;
  public isWatchWithAccessCodeClick: boolean = false;
  public isTimeLeftLessOrEqual15: boolean;
  public isLiveEventBuyed: string;
  public viewEventFlag: boolean;
  public DomainName: string;
  public currentTimeZone: string;
  public isPaymentDisable: boolean = false;
  public isWatchEventDisable: boolean = false;
  public showLoading: boolean = true;
  public channelButtonActive = false;
  public extraButtonActive = false;
  public timerInterval: any;
  public contentBackground: string;
  public domain: string;
  public title: string;
  public timeDiff;
  public loginButtonActive = true;
  public paymentButtonActive = true;
  public watcheventButtonActive = true;
  public resendButtonActive = true;
  public eventCounterShow = true;
  public myAngularxQrCode: string = null;
  public eventFooterLink = true;
  public textAboveButtonActive = true;
  public streamType: StreamType;
  public isEventStart: boolean = false;
  public stream_addthis_id: string;
  public contentBackgroundStyle: string;
  public promoLinkActive: boolean;
  public iosAppAvailable: boolean;
  public androidAppAvailable: boolean;
  public streamCorrect: boolean;
  public clientDetails1: any;
  public appDetails1: any;
  public appBundlesHP: any;
  public appDesign: any;
  public isClientPage: boolean = true;
  public isHomePage: boolean = true;
  private subs = new SubSink();
  //#endregion


  //#endregion

  //#region life-cycle hook

  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private storageService: StorageService,
    private loginService: LoginService,
    private location: Location,
    private donatePerViewService: DonatePerViewService
  ) {


  }

  ngOnInit(): void {
   // console.log("result", this.result);
    if (this.result) {
      //this.showLoading = false;
      this.streamCorrect = true;
      if(this.result.HomePageVar)
      {
       // this.appDesign.app_logo = "";
       this.isHomePage = true;
      }
      else
      {
      
      this.clientDetails1 = this.result;
      this.appDetails1 = this.result.data.appDetails;
      this.appDesign = this.result.data.appDesigns;
      this.appBundlesHP = this.result.data.appBundles;
      this.isClientPage = this.result.ClientPageVar;
      this.isHomePage = false;
      }
    }
    else
      this.streamCorrect = false;
    //this.showLoading = true;
    //this.setClientDetails();
    // this.setBundleDetails();
    // this.setStreamDetails();

   // console.log("streamCorrect", this.streamCorrect);
    let data = this.storageService.get(AppStorage.data, true);
    if (data) {
      this.userData = JSON.parse(data);
      this.isUserLogin = true;
    }
  }

  //#endregion

  

  //#endregion

  //#region public methods

  public openLoginModal(): void {
    this.modalRef = this.modalService.show(
      LoginComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openRegisterModal(): void {
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-md' })
    );
  }

  public openSupportModal(): void {
    this.modalRef = this.modalService.show(
      SupportComponent,
      Object.assign({ ignoreBackdropClick: true }, { class: 'gray modal-lg' })
    );
  }

  public resendTicketCode() {
    //alert("sdf");
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);

    try {
      let appCode = this.storageService.get(AppStorage.appCodeLS, true);
      var formDataC = new FormData();
      // console.log("str = ", this.streamDetailsFull);
      formDataC.append("userCode", this.userData.userCode);
      formDataC.append("menuGuid", this.streamDetailsFull.menu_guid);
      formDataC.append("streamGuid", this.streamDetailsFull.stream_guid);
      //formDataC.append("appId", appCode);
      formDataC.append("appId", this.streamDetailsFull.app_code);

      this.subs.sink = this.donatePerViewService.sendTicketCode(formDataC)
        .subscribe(
          async (response: any) => {
           // console.log('response: ', response);
          });
    } catch (error) {
      //console.log('error: ', error);
    }
  }

  public onLogout(): void {
    this.loginService.onLogout();
    window.location.reload();
  }

  public onMyProfileClick(): void {
    this.router.navigateByUrl(`/myprofile`);
  }

  public onMyPaymentHistoryClick(): void {
    this.router.navigateByUrl(`/mypaymenthistory`);
  }

  //#endregion

}
