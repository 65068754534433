<div *ngIf="streamCorrect">
  <div style=" {{streamDetailsFull.stream_design.body_background_style}} " *ngIf="!showLoading">
    <div style="width: 100%;" *ngIf="layoutZero">
      <div>
        <!--div>
          <div class="header_text" style="background-color:{{streamDetailsFull.stream_design.header_background_color}};  
          text-align:center; {{streamDetailsFull.stream_design.header_text_style}}">
            <div class="logout_btn d-flex w-100 mr-3 login_box" style="width: 50%;justify-content: flex-end; ">
              <div class="col-lg-4 col-sm-12" style="float: left; text-align: center;  padding-left: 15px; padding-bottom: 5px; background-color: #e3331b; ">
                &nbsp;</div>
                <div class="col-lg-8 col-sm-12" style="border: red 1px solid; float: right;">
              <div *ngIf="!isUserLogin" style="float: right; width: 70%;">
                <button class="pt-2 pb-2 text-right login cursor-pointer" style="background-color: #1055a4"
                  (click)="openLoginModal()" *ngIf="loginButtonActive">
                  <i class="fa fa-sign-in" aria-hidden="true"></i>
                  <span class="hidden-sm"> Login </span>
                </button>
                <button class="pt-2 pb-2 text-right register cursor-pointer" style="background-color:#a42310"
                  (click)="openRegisterModal()" *ngIf="loginButtonActive">
                  <i class="fa fa-user-plus" aria-hidden="true"></i>
                  <span class="hidden-sm"> Register </span>
                </button>
              </div>
              <div style="float: right; width: 30%;">
                <button class="pt-2 pb-2 text-right cursor-pointer"
                  [ngStyle]="{'background-color': isUserLogin ? '#37a7df' : '#a42310'}" (click)="openSupportModal()">
                  <i class="fa fa-question-circle" aria-hidden="true"></i>
                  <span class="hidden-sm"> Support </span>
                </button>
              </div>
              <div *ngIf="isUserLogin">
                <button class="pt-2 pb-2 text-right logout" style="cursor: pointer; ;background-color:#1055a4"
                  (click)="resendTicketCode()" *ngIf="loginButtonActive">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <span class="hidden-sm"> Re-send Code </span>
              </button>
                <button class="pt-2 pb-2 text-right logout cursor-pointer" style="background-color:#1055a4"
                  (click)="onLogout()" *ngIf="loginButtonActive">
                  <i class="fa fa-sign-out" aria-hidden="true"></i>
                  <span class="hidden-sm"> Logout </span>
                </button>
              </div>
            </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-sm-12" style="float: left; text-align: center;  padding-left: 15px; padding-bottom: 5px; background-color: #e3331b; ">
                <a href="{{streamDetailsFull.stream_logo_url}}" target="_blank" ><img src="{{streamDetailsFull.stream_logo}}" height="90px"></a></div>
              <div class="col-lg-8 col-sm-12 " style="font-family: OPTILatin;  padding-top: 10px; float: right; height: 90px; text-align: center; ">{{streamDetailsFull.stream_title}}
              </div>
            </div>
          </div>
    
        </div-->

        <div>
          <div class="" style="background-color:{{streamDetailsFull.stream_design.header_background_color}};  
          text-align:center; {{streamDetailsFull.stream_design.header_text_style}}">

            <div class="row">
              <div class="col-lg-4 col-sm-12" style="height: 90px; float: left; text-align: center; padding-bottom: 0px !important; 
              padding-right: 15px !important; ">
                <div style="background-color: #e3331b; padding-left: 0px; 
              height: 90px;  padding-top: 10px; font-family: 'Tw Cen MT Condensed';color: white; 
              text-shadow: 2px 2px 2px #252525; font-weight: normal !important;">
                  <div style=" font-size: 30px;"> SWIG MEDIA </div>
                  <div style=" font-size: 24px; margin-top: -10px;">presents </div>
                  <!--a href="{{streamDetailsFull.stream_logo_url}}" target="_blank"> <img src="../../assets/swigmedia-top.png" width="80%"></a-->
                </div>

              </div>
              <div class="col-lg-6 col-sm-12 " style="font-family: 'optilatin-boldcondensed';  padding-top: 10px; float: right; height: 90px; 
              text-align: center; ">
                <!--{{streamDetailsFull.stream_title}}-->
                <img src="../../assets/event-name-pre.png" width="80%">
              </div>
              <div class="col-lg-2 col-sm-12 pl-4 pr-4" style="float: right; text-align: center; ">
                <div class="logout_btn d-flex w-100 mr-3 login_box" style="justify-content: flex-end; ">


                  <div *ngIf="!isUserLogin">
                    <button class="pt-3 pb-3 text-right login cursor-pointer" style="background-color: #1055a4"
                      (click)="openLoginModal()" *ngIf="loginButtonActive">
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                      <span class="hidden-sm"> Login </span>
                    </button>
                    <button class="pt-3 pb-3 text-right register cursor-pointer" style="background-color:#1055a4"
                      (click)="openRegisterModal()" *ngIf="loginButtonActive">
                      <i class="fa fa-user-plus" aria-hidden="true"></i>
                      <span class="hidden-sm"> Register </span>
                    </button>
                    <button class="pt-3 pb-3 text-right cursor-pointer"
                      [ngStyle]="{'background-color': isUserLogin ? '#37a7df' : '#a42310'}"
                      (click)="openSupportModal()">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                      <span class="hidden-sm"> Support </span>
                    </button>
                  </div>

                  <div *ngIf="isUserLogin">
                    <!-- <button class="pt-3 pb-3 text-right logout" style="cursor: pointer; ;background-color:#1055a4"
                      (click)="resendTicketCode()" *ngIf="loginButtonActive">
                      <i class="fa fa-user" aria-hidden="true"></i>
                      <span class="hidden-sm"> Re-send Code </span>
                    </button>
                    <button class="pt-3 pb-3 text-right logout cursor-pointer" style="background-color:#1055a4"
                      (click)="onLogout()" *ngIf="loginButtonActive">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                      <span class="hidden-sm"> Logout </span>
                    </button> -->
                    <button class="pt-3 pb-3 text-right cursor-pointer"
                      [ngStyle]="{'background-color': isUserLogin ? '#37a7df' : '#a42310'}"
                      (click)="openSupportModal()">
                      <i class="fa fa-question-circle" aria-hidden="true"></i>
                      <span class="hidden-sm"> Support </span>
                    </button>
                    <div class="btn-group ml-3" dropdown>
                      <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                        aria-controls="dropdown-autoclose1">
                        <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                      </div>
                      <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-autoclose1">
                        <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                          <span class="dropdown-item">
                            My Profile
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                          <span class="dropdown-item">
                            Payment History
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Swigits
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Purchases
                          </span>
                        </li>
                        <!-- <li role="menuitem" class="cursor-pointer">
                                                    <span class="dropdown-item" (click)="resendTicketCode()">
                                                        Re-send Code
                                                    </span>
                                                </li> -->
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                          <span class="dropdown-item" (click)="onLogout()">
                            Logout
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div style="{{contentBackgroundStyle}}">
          <div class="row" style="padding-top: 20px; padding-bottom: 20px;">
            <div class="col-lg-4 col-sm-12 " style="text-align: center;  ">
              <div style="width: 100%; align-self:auto; ">
                <img width="100%" src="{{streamDetailsFull.stream_thumb_image}}"
                  style=" {{streamDetailsFull.stream_design.poster_image_style	}}">
                <br><bR>
                <div class="hidden-sm counter_class" *ngIf="eventCounterShow"
                  style="{{streamDetailsFull.stream_design.counter_style}} width: 100%; display: block; background-color: #eaad38; padding-top:5px; padding-bottom: 10px">

                  {{ secondsToTime(timeDiff) }}
                </div>
                <span *ngIf="isEventStart" class="hidden-sm counter_class"
                  style="{{streamDetailsFull.stream_design.counter_style}}">
                  Event is Ended.
                </span>
                <br><bR>
                <span class="hidden-sm" style="{{streamDetailsFull.stream_design.text_below_poster_style}}"
                  [innerHTML]="textBelowPoster">

                </span>

              </div>


              <div style="width: 100%;">

              </div>
            </div>
            <div class="col-lg-6 col-sm-12 " style=" text-align: center;">

              <div class="w-100">
                <div *ngIf="textAboveButtonActive" style="{{streamDetailsFull.stream_design.text_above_buttons_style}}">
                  {{streamDetailsFull.stream_design.text_above_buttons}}
                </div>

                <div class="row" style="text-align: center;">

                  <div class="col-md-1 hidden-cell" style="float: left; height: 50px;"></div>
                  <div class="col-md-4 col-sm-12" style="float: left; height: 50px;" *ngIf="!promoLinkActive">

                    <button type="button" class="desButton cursor-pointer"
                      (click)="onPromoClick()">{{streamDetailsFull.stream_design.promo_btn_text}}</button>
                  </div>
                  <div class="col-md-1 hidden-cell" style="float: left; height: 50px;"></div>
                  <div class="col-md-4 col-sm-12" style="float: left; height: 50px;" *ngIf="promoLinkActive">
                    <button type="button" class="desButton cursor-pointer"
                      (click)="openLink()">{{streamDetailsFull.stream_design.promo_btn_text}}</button>

                  </div>
                  <div class="col-md-4 col-sm-12" style="float: left; height: 50px;" *ngIf="extraButtonActive">
                    <button type="button" class="desButton cursor-pointer"
                      (click)="onExtraButtonClick()">{{streamDetailsFull.stream_design.extra_video_btn_text}}</button>
                  </div>
                  <div class="col-md-1  hidden-cell" style="float: left; height: 50px;"></div>
                </div>

                <div class="row" style="text-align: center;">
                  <div class="col-md-1 hidden-cell" style="float: left; height: 50px;"></div>
                  <div class="col-md-4 col-sm-12" style="float: left; height: 50px;" *ngIf="channelButtonActive">
                    <button type="button" class="desButton cursor-pointer"
                      (click)="onChannelButtonClick()">{{streamDetailsFull.stream_design.channel_video_btn_text}}</button>


                  </div>
                  <div class="col-md-4 col-sm-12" style="float: left; height: 50px;" *ngIf="paymentButtonActive">
                    <button type="button" class="desButton" (click)="onDonatePerViewClick()"
                      [disabled]="isPaymentDisable"
                      style="background: url(./assets/buyticket-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      [ngStyle]="{'cursor': isPaymentDisable ? 'not-allowed' : 'pointer'}">
                      <!--{{streamDetailsFull.stream_design.payment_btn_text}}-->
                    </button>

                  </div>
                  <div class="col-md-1 hidden-cell" style="float: left; height: 50px;"></div>
                  <div class="col-md-4 col-sm-12" style="height: 50px; text-align: center;"
                    *ngIf="watcheventButtonActive">
                    <button *ngIf="!viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_accesscode_btn_text}}-->
                    </button>
                    <button *ngIf="viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_btn_text}}-->
                    </button>
                  </div>
                  <div class="col-md-1 hidden-cell" style="float: left; height: 50px;"></div>
                  <!--div class="col-md-4 col-sm-12" style=" height: 50px;" >
                    <button type="button" class="desButton" (click)="openQNALink()"   
                    style="background: url(./assets/watchwithqna-btn.png) no-repeat; background-size: 100% 100%; height: 42px !important; border: 0px; 
                    cursor: pointer; background-position: center;"
                    -->
                  <!--{{streamDetailsFull.stream_design.payment_btn_text}}-->
                  <!--/button>
                     
                    </div-->
                  <!--iv class="col-md-3" style="float: left; height: 50px;">
                    <button type="button" class="desButton cursor-pointer" (click)="onWatchEventClick()">Watch Event Directly</button>
                  </div-->
                </div>
                <!--div class="row" style="padding-left:15px; white-space: pre-line; 
                text-align: left;{{streamDetailsFull.stream_design.description_text_style}}" [innerHTML]="streamDescFull"-->
                <!-- {{}} -->
                <div class="row" style=" white-space: pre-line; 
                  text-align: left;">
                  <div class="col-md-12 col-sm-12"><img src="../../assets/cast-img-2.png" style="width: 100%;"></div>

                </div>
                <div
                  style="padding-left: 15px; padding-top: 15px; text-align: left; {{streamDetailsFull.stream_design.sharethis_text_style}}">


                  <!-- Go to www.addthis.com/dashboard to customize your tools 
                    {{streamDetailsFull.stream_design.sharethis_text}} -->

                </div>

                <div style="height: 10px;"> </div>
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 " style="text-align: center; padding-right: 20px; padding-left: 20px;">
              <div style=" padding-top: 10px; font-family: 'Tw Cen MT Condensed';color: #e3331b; 
               font-weight: normal !important; font-size: 22px; ">
                Please <u>Share</u> this on your social networks.
                <!--a href="{{streamDetailsFull.stream_logo_url}}" target="_blank"> <img src="../../assets/swigmedia-top.png" width="80%"></a-->
                <hr
                  style="border-top: 1px solid  #e3331b !important; padding-top: 0px !important; padding-bottom: 0px !important; width: 80%;">
              </div>
              <div>
                <!--img src="../../assets/award1.png"-->

              </div>

              <div *ngFor="let links of this.streamDetailsFull.stream_links;">

                <li *ngIf="links.link_type == 'TL'" style="list-style-type:none; padding-bottom: 20px;">
                  <a href="{{links.link_url}}" style="{{streamDetailsFull.stream_design.link_style}}"
                    target="_blank">{{links.link_text}}</a>
                </li>
                <li *ngIf="links.link_type == 'IL'" style="list-style-type:none; padding-bottom: 20px;">
                  <a href="{{links.link_url}}" target="_blank"><img src="{{links.link_image_path}}"
                      style="{{streamDetailsFull.stream_design.link_image_style}}"></a>
                </li>
                <li *ngIf="links.link_type == 'IO'" style="list-style-type:none; padding-bottom: 20px;">
                  <img src="{{links.link_image_path}}" style="{{streamDetailsFull.stream_design.link_image_style}}">
                </li>
                <li *ngIf="links.link_type == 'TO'" style="list-style-type:none; padding-bottom: 20px; ">
                  <span style="{{streamDetailsFull.stream_design.link_area_text_style}}">{{links.only_text}}</span>
                </li>
              </div>

              <div class="QRCodeClass" style=" ">
                <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'" [colorLight]="'#d9d9d9'"
                  [cssClass]="'qrcode'"></qrcode>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div
        style='width: 100%;   background-color:{{streamDetailsFull.stream_design.footer_background_color}}; {{streamDetailsFull.stream_design.footer_style}}'
        class="row">
        <div class="col-lg-4 col-sm-12 footer_1"
          style="padding-bottom: 10px;padding-top: 10px; {{streamDetailsFull.stream_design.copyright_text_style}} ">
          {{streamDetailsFull.stream_design.copyright_text}}
        </div>
        <div class="col-lg-6 col-sm-12 footer_2" *ngIf="eventFooterLink"
          style="padding-bottom: 10px; padding-top: 10px; {{streamDetailsFull.stream_design.footer_text_style}}">
          <a href="{{streamDetailsFull.stream_design.footer_link_url}}"
            target="_blank">{{streamDetailsFull.stream_design.footer_link_text}}</a>
        </div>
        <div class="col-lg-6 col-sm-12 footer_2" *ngIf="!eventFooterLink"
          style="padding-bottom: 10px; {{streamDetailsFull.stream_design.footer_text_style}}">

          {{streamDetailsFull.stream_design.footer_link_text}}
        </div>
        <div class="col-lg-2 col-sm-12 footer_3"
          style=" background-color: #e3331b; height: 60px; text-align: center; padding-bottom: 70px;">
          <a href="{{streamDetailsFull.stream_design.stream_android_app_url}}" *ngIf="androidAppAvailable"
            target="_blank"><img src="../../assets/google-play-badge.png" height="50px;"></a>
          <a href="{{streamDetailsFull.stream_design.stream_ios_app_url}}" *ngIf="iosAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/apple-store-badge.png" height="50px"></a>
          <a href="../../assets/TV-Instructions-Celebration.pdf" download style="font-family: 'Tw Cen MT Condensed'; font-size: 34px;color: white; text-shadow: 2px 2px 2px #252525; 
          ">How to Watch on TV</a>

        </div>
      </div>
    </div>
    <!--    HITLAB LAYOUT    -->
    <div style="width: 100%;" *ngIf="layoutOne">
      <!-- header of website -->
      <header>
        <section  style="padding: 0px !important; ">
          <!--wrapper-->
          <div class="topBar">

            <!--Logo-->
            <div class="topBar_space">
              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                  src="{{streamDetailsFull.stream_logo}}" class="topBar--logo"></a>

            </div>

            <!--title-->
            <div class="topBar--title">
              <h1> {{streamDetailsFull.stream_design.streamHeader}}</h1>
            </div>

            <!--menu navigation-->
            <div class="topBar_menu">
              <div *ngIf="loginButtonActive" class="topBar_menu--item">
                <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                </div>
                <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                  REGISTER
                </div>
                <!-- <div class="pt-2 pb-2 topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                </div> -->
                <div class=" topBar_menu--item1 cursor-pointer" (click)="openSupportModal()">SUPPORT</div>
                <div class="btn-group ml-3" dropdown *ngIf="isUserLogin">
                  <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                    aria-controls="dropdown-autoclose1">
                    <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                  </div>
                  <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-autoclose1">
                    <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                      <span class="dropdown-item">
                        My Profile
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                      <span class="dropdown-item">
                        Payment History
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Swigits
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Purchases
                      </span>
                    </li>
                    <!-- <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="resendTicketCode()">
                                                    Re-send Code
                                                </span>
                                            </li> -->
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                      <span class="dropdown-item" (click)="onLogout()">
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--
              <div *ngIf="!isUserLogin" class="topBar_menu">
                  <div class="topBar_menu--item cursor-pointer" 
                    (click)="openLoginModal()" *ngIf="loginButtonActive">
                    
                    <span class="hidden-sm"> LOGIN </span>
              </div>
                  <div class="  topBar_menu--item cursor-pointer" 
                    (click)="openRegisterModal()" *ngIf="loginButtonActive">
                   
                    <span class="hidden-sm"> REGISTER </span>
            </div>
                  <div class=" topBar_menu--item cursor-pointer"
                     (click)="openSupportModal()">
                    
                    <span class="hidden-sm"> SUPPORT </span>
          </div>
                </div>
                
              <div *ngIf="isUserLogin" class="topBar_menu">
                  <div class="pt-2 pb-2 topBar_menu--item" style="cursor: pointer;"
                    (click)="resendTicketCode()" *ngIf="resendButtonActive">
                    
                    <span class="hidden-sm"> RE-SEND CODE </span>
              </div>
              <div class="pt-2 pb-2 topBar_menu--item" style="cursor: pointer;"
                   *ngIf="!resendButtonActive">
                    
                    <span class="hidden-sm"> &nbsp;</span>
              </div>
                  <div class="pt-2 pb-2 topBar_menu--item cursor-pointer" 
                    (click)="onLogout()" *ngIf="loginButtonActive">
                    
                    <span class="hidden-sm"> LOGOUT </span>
            </div>
                  <div class="pt-2 pb-2 topBar_menu--item cursor-pointer"
                     (click)="openSupportModal()">
                    
                    <span class="hidden-sm"> SUPPORT </span>
          </div>
                </div>

              -->
          </div>
        </section>
      </header>

      <!-- body of website -->
      <section class="bodyForSite" style="{{contentBackgroundStyle}}">
        <div style="width: 100%; text-align: center; background-color: #000000d9;">
          <div style="width: 80%; display: inline-block; margin: 0 auto; padding: 2rem 0 2rem 0;">
            <!--wrapper-->
            <div class="contentWrapper">
              <!--image-->
              <div class="bodyForSite_image">
                <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster"
                  class="bodyForSite--poster">
              </div>

              <!--information-->
              <div class="bodyForSite_information">

                <!--title-->
                <div class="small_title">
                  <h2>
                    {{streamDetailsFull.stream_design.text_above_buttons}}
                  </h2>
                </div>
                
               
                <!--buttons-->
                <div class="content--information">


                  <button type="button" class="watchButton cursor-pointer" *ngIf="!promoLinkActive"
                    (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                    <img src="../../assets/watch_promo.svg" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>
                  <button type="button" class="watchButton cursor-pointer" *ngIf="promoLinkActive"
                    (click)="openLink()"><img src="../../assets/watch_promo.svg" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>

                  <button type="button" class="watchButton cursor-pointer" *ngIf="channelButtonActive"
                    (click)="onChannelButtonClick()">
                    <img src="../../assets/watch_live.svg" class="buttonIcon2">
                    {{streamDetailsFull.stream_design.channel_video_btn_text}}</button>

                </div>
                <div class="content--information" *ngIf="paymentButtonActive">

                  <button type="button" class="watchButton cursor-pointer" *ngIf="extraButtonActive"
                    (click)="onExtraButtonClick()">{{streamDetailsFull.stream_design.extra_video_btn_text}}</button>



                  <button type="button" class="desButton" (click)="onDonatePerViewClick()" [disabled]="isPaymentDisable"
                    *ngIf="paymentButtonActive"
                    style="background: url(./assets/buyticket-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                    [ngStyle]="{'cursor': isPaymentDisable ? 'not-allowed' : 'pointer'}">
                    <!--{{streamDetailsFull.stream_design.payment_btn_text}}-->
                  </button>


                  <div *ngIf="watcheventButtonActive">
                    <button *ngIf="!viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_accesscode_btn_text}}-->
                    </button>
                    <button *ngIf="viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_btn_text}}-->
                    </button>
                  </div>

                </div>

                <!--description-->
                <div class="content--information2" style="text-align: left;" [innerHTML]="streamDescFull">

                </div>
                <div class="content--border" style="text-align: left;">
                  <strong>Be the first to watch.</strong> &nbsp; Register &nbsp;<a style="cursor: pointer; color: blue;"
                    (click)="firstToWatchLink()"><strong> here </strong></a>&nbsp; to join our Beta
                  launch<br><br>
                  <strong>Do you have the talent it takes to be signed to a recording contract...</strong>in any genre
                  and language?
                  Register <a style="cursor: pointer; color: blue;"
                    (click)="participateLink()"><strong>here</strong></a> to
                  participate in our ‘Global Emerging Artists Search’ Showcase. Instructions will follow<br><Br>
                  <form [formGroup]="formS">
                    <div class="form-group">
                      LANGUAGE OPTIONS: To watch the intro to the 'Digital Emerging Artists Search' in one of 21
                      languages,
                      please select language &nbsp;


                      <select formControlName="website" (change)="changeLang($event)" style="height: 25px;">
                        <option value="" [selected]="true">Here</option>
                        <option value="https://vimeo.com/689983062">English</option>
                        <option value="https://vimeo.com/693277130">Spanish</option>
                        <!--option  value="" >Swahili</option-->
                        <option value="https://vimeo.com/693275213">Arabic</option>
                        <option value="https://vimeo.com/693281975">Hebrew</option>
                        <option value="https://vimeo.com/693281140">French</option>
                        <option value="https://vimeo.com/693282912">Italian</option>
                        <option value="https://vimeo.com/693279458">Brazil</option>
                        <option value="https://vimeo.com/693281539">German</option>
                        <option value="https://vimeo.com/693277518">Swedish</option>
                        <option value="https://vimeo.com/693280241">Czech</option>
                        <option value="https://vimeo.com/693280679">French African</option>
                        <option value="https://vimeo.com/693277856">Twi</option>
                        <option value="https://vimeo.com/693278728">Wolof</option>
                        <option value="https://vimeo.com/693279087">Bambara</option>
                        <option value="https://vimeo.com/693276376">Romania</option>
                        <option value="https://vimeo.com/693282391">Hungary</option>
                        <option value="https://vimeo.com/693276761">Serbia</option>
                        <option value="https://vimeo.com/693278292">Ukraine</option>
                        <option value="https://vimeo.com/693279855">Croatia</option>
                        <option value="https://vimeo.com/693283484">Poland</option>
                        <option value="https://vimeo.com/693274130">Albania</option>
                      </select>
                      <br>
                      <div style="font-size: 2.2rem;"><img src="../../assets/image_global.png" width="90px"> &nbsp;
                        Talent Search Without Borders </div>

                    </div>

                  </form>
                </div><br><br>


                <!--social network sharing-->
                <div class="content--information_social" style="text-align: left;">
                  <h2>
                    Share on social network
                  </h2>
                  <div class="content_position">

                    <iframe src="https://hitlab.swigit.com/sharethisframe.html" style="border: none; overflow: hidden;"
                      width="100%" height="100px"></iframe>
                  </div>
                </div>

                <!--QR code-->
                <div class="QRcode">

                  <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- footer for website -->
      <section class="siteFooter"
        style="width: 100%; background-color:{{streamDetailsFull.stream_design.footer_background_color}}; ">

        <div class="siteFooter_logos" style="width: 70%; ">

          <a href="{{streamDetailsFull.stream_design.stream_android_app_url}}" *ngIf="androidAppAvailable"
            target="_blank"><img src="../../assets/googleplay_logo.svg" class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_ios_app_url}}" *ngIf="iosAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/appleTV_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_firetv_app_url}}" *ngIf="firetvAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/amazonFire_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_roku_app_url}}" *ngIf="rokuAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/roku_logo.svg"
              class="footer--logo"></a>
        </div>

        <div class="siteFooter_copyInfo" style=" width: 30%; float: right;  ">
          {{streamDetailsFull.stream_design.copyright_text}} <br>
          {{streamDetailsFull.stream_design.footer_link_text}}
        </div>
      </section>
    </div>
    <!--    CELEBRATION LAYOUT    -->
    <div style="width: 100%;" *ngIf="layoutTwo">


      <div>

        <div>
          <div class="" style="background-color:{{streamDetailsFull.stream_design.header_background_color}};  
    text-align:center;">
            <header>
              <section  style="padding: 0px !important; ">
                <!--wrapper-->
                <div class="topBar" style="{{streamDetailsFull.stream_design.header_style}}">

                  <!--Logo-->
                  <div class="topBar_space">
                    <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                        src="{{streamDetailsFull.stream_logo}}" class="topBar--logo"></a>

                  </div>

                  <!--title-->
                  <div class="topBar--title" style="{{streamDetailsFull.stream_design.header_style}}">
                    <h1 style="{{streamDetailsFull.stream_design.header_text_style}}">
                      {{streamDetailsFull.stream_design.streamHeader}}</h1>
                    <span
                      style="{{streamDetailsFull.stream_design.header_tagline_style}}">{{streamDetailsFull.stream_design.stream_header_tagline}}</span>
                  </div>

                  <!--menu navigation-->
                  <div class="topBar_menu">


                    <div *ngIf="loginButtonActive" class="topBar_menu--item">
                      <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">
                        LOGIN
                      </div>
                      <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin"
                        (click)="openRegisterModal()">REGISTER
                      </div>
                      <!-- <div class="pt-2 pb-2 topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">
                        LOGOUT
                      </div> -->
                      <div class=" topBar_menu--item1 cursor-pointer" (click)="openSupportModal()">SUPPORT
                      </div>
                      <div class="btn-group ml-3" dropdown *ngIf="isUserLogin">
                        <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                          aria-controls="dropdown-autoclose1">
                          <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                        </div>
                        <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-autoclose1">
                          <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                            <span class="dropdown-item">
                              My Profile
                            </span>
                          </li>
                          <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                            <span class="dropdown-item">
                              Payment History
                            </span>
                          </li>
                          <li role="menuitem" class="cursor-pointer">
                            <span class="dropdown-item">
                              My Swigits
                            </span>
                          </li>
                          <li role="menuitem" class="cursor-pointer">
                            <span class="dropdown-item">
                              My Purchases
                            </span>
                          </li>
                          <!-- <li role="menuitem" class="cursor-pointer">
                                                      <span class="dropdown-item" (click)="resendTicketCode()">
                                                          Re-send Code
                                                      </span>
                                                  </li> -->
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                            <span class="dropdown-item" (click)="onLogout()">
                              Logout
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>


                    <!-- <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;" (click)="resendTicketCode()"
                      *ngIf="isUserLogin === true && resendButtonActive === true ">
                      <span class="hidden-sm" style="color: #e4e4e4;"> RE-SEND CODE </span>
                    </div> -->
                  </div>
                </div>
              </section>
            </header>

          </div>

        </div>
        <div style="{{contentBackgroundStyle}}">
          <div class="row" style="  {{streamDetailsFull.stream_design.content_background_alpha}} ">

            <div class=" " style=" text-align: center;">


              <div class="bodyContainer">

                <!-- main content -->
                <div class="bodyContainer_main">

                  <!-- poster -->

                  <div class="bodyContainer--poster" style="width: 100%; align-self:auto; padding: 0px 10px 0px 10px ">
                    <img width="100%" src="{{streamDetailsFull.stream_thumb_image}}"
                      style=" {{streamDetailsFull.stream_design.poster_image_style	}}">
                    <br><bR>
                    <div class="hidden-sm counter_class" *ngIf="eventCounterShow"
                      style="{{streamDetailsFull.stream_design.counter_style}} width: 100%; display: block;  padding-top:5px; padding-bottom: 10px">

                      {{ secondsToTime(timeDiff) }}
                    </div>
                    <!--span *ngIf="isEventStart" class="hidden-sm counter_class"
                    style="{{streamDetailsFull.stream_design.counter_style}}">
                    Event is Ended.
                  </span-->
                    <br><bR>
                    <span class="hidden-sm" style="{{streamDetailsFull.stream_design.text_below_poster_style}}"
                      [innerHTML]="textBelowPoster"></span>

                  </div>

                  <!-- information block -->
                  <div class="bodyContainer--information">


                    <!-- buttons -->
                    <div class="bodyContainer_buttonsBig">
                      <!--  onDonatePerViewClick()        ="" -->
                      <button class="watchButton" (click)="openExtraLink()">
                        <img src="assets/watch_promo.svg" class="buttonIcon">
                        BUY TICKET ($20)
                      </button>
                      <button class="watchButton" *ngIf="!viewEventFlag" [disabled]="isWatchEventDisable"
                        [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                        (click)="onWatchWithTCClick()">
                        <img src="assets/watch_live.svg" class="buttonIcon2">
                        WATCH WITH TICKET CODE
                      </button>
                      <button class="watchButton" *ngIf="viewEventFlag" [disabled]="isWatchEventDisable"
                        [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                        (click)="onWatchWithTCClick()">
                        <img src="assets/watch_live.svg" class="buttonIcon2">
                        WATCH WITH TICKET CODE
                      </button>


                    </div>

                    <!-- small buttons -->
                    <div class="bodyContainer_buttonsBig">
                      <button class="watchButton--small" *ngIf="!promoLinkActive"
                        (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                        <img src="assets/trailer.svg" class="buttonIcon">
                        TRAILER
                      </button>
                      <button class="watchButton--small" (click)="openExtraLink2()">
                        <img src="assets/program_description.svg" class="buttonIcon2">
                        PROGRAM DESCRIPTION
                      </button>
                    </div>

                    <!-- title -->
                    <div class="body_title" style="text-align: left;">
                      <h2>CELEBRATION BROADWAY JEWISH COMPOSERS <span class="title--small">(90 min.)</span></h2>
                    </div>

                    <!-- text before actors -->
                    <div class="text_actors" style="text-align: left;">
                      <p class="text_actors_i">
                        Hosted by Kari Howard and Michael Orland<br>
                        Featuring Memorable Performances by:<br><br>
                        Valerie Perri, Jason Graae, Alisan Porter, David Burnham
                        Robert Yacko, Jenna Rosen, Sam Gravitte, Kari Howard
                        and Special Guest Particia Ward Kelly<br><br>
                        Musical Director/Pianist: Michael Orland<br><br>
                        Producer/Director: Ivan Gulas<br><br>
                        Cast:
                      </p>

                      <!-- pictures -->
                      <div class="actors_pictures">
                        <img src="assets/actor_1.png" alt="Kari Howard" class="pictureA">
                        <img src="assets/actor_2.png" alt="Michael Orland" class="pictureA">
                        <img src="assets/actor_3.png" alt="Jason Graae" class="pictureA">
                        <img src="assets/actor_4.png" alt="Jenna Lea Rosen" class="pictureA">
                        <img src="assets/actor_5.png" alt="Valerie Perri" class="pictureA">
                        <img src="assets/actor_6.png" alt="Robert Yacko" class="pictureA">
                        <img src="assets/actor_7.png" alt="David Burnham" class="pictureA">
                        <img src="assets/actor_8.png" alt="Sam Gravitte" class="pictureA">
                        <img src="assets/actor_9.png" alt="Alisan Porter" class="pictureA">
                        <img src="assets/actor_10.png" alt="Kimberley Locke" class="pictureA">
                      </div>
                    </div>
                  </div>



                </div>
                <div class="bodyContainer_social"
                  style="text-align: center; padding-top: 20px; padding-right: 20px; padding-left: 20px; {{streamDetailsFull.stream_design.links_area_style}}">
                  <div style=" padding-bottom: 10px;{{streamDetailsFull.stream_design.sharethis_text_style}}">
                    {{streamDetailsFull.stream_design.sharethis_text}}
                  </div>
                  <div style="padding-bottom: 10px;">
                    <!--img src="../../assets/award1.png"-->
                    <iframe src="https://celebration.swigit.com/sharethisframe.html"
                      style="border: none; overflow: hidden;" width="95%" height="200px"></iframe>
                  </div>
                  <div *ngFor="let links of this.streamDetailsFull.stream_links;">

                    <li *ngIf="links.link_type == 'TL'" style="list-style-type:none; padding-bottom: 20px;">
                      <a href="{{links.link_url}}" style="{{streamDetailsFull.stream_design.link_style}}"
                        target="_blank">{{links.link_text}}</a>
                    </li>
                    <li *ngIf="links.link_type == 'IL'" style="list-style-type:none; padding-bottom: 20px;">
                      <a href="{{links.link_url}}" target="_blank"><img width="90%" src="{{links.link_image_path}}"
                          style="{{streamDetailsFull.stream_design.link_image_style}}" w></a>
                    </li>
                    <li *ngIf="links.link_type == 'IO'" style="list-style-type:none; padding-bottom: 20px;">
                      <img src="{{links.link_image_path}}" width="90%"
                        style="{{streamDetailsFull.stream_design.link_image_style}}">
                    </li>
                    <li *ngIf="links.link_type == 'TO'" style="list-style-type:none; padding-bottom: 20px; ">
                      <div style="{{streamDetailsFull.stream_design.link_area_text_style}}">{{links.only_text}}</div>
                    </li>
                  </div>

                  <div class="QRCodeClass" style=" ">
                    <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                      [colorLight]="'#d9d9d9'" [cssClass]="'qrcode'"></qrcode>
                  </div>
                </div>

              </div>


              <div class="siteFooter">

                <!-- copyright information -->
                <div class="siteFooter_copyInfo" style="text-align: left;">
                  {{streamDetailsFull.stream_design.copyright_text}}<br>
                  {{streamDetailsFull.stream_design.footer_link_text}}
                </div>

                <!-- how to watch on TV -->
                <div class="siteFooter_TV">
                  <a class="siteFooter_TV--watch" href="../../assets/TV-Instructions-Celebration.pdf" download>HOW TO
                    WATCH ON TV</a>

                </div>
              </div>

            </div>

          </div>
        </div>

      </div>


    </div>
    <!--    C2CTV LAYOUT    -->
    <div style="width: 100%;" *ngIf="layoutThree">
      <!-- header of website -->
      <header>
        <section  style="padding: 0px !important; ">
          <!--wrapper-->
          <div class="topBar">

            <!--Logo-->
            <div class="topBar_space">
              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                  src="{{streamDetailsFull.stream_logo}}" class="topBar--logo" style="width: 100% !important;"></a>

            </div>

            <!--title-->
            <div class="topBar--title">
              <h1> {{streamDetailsFull.stream_design.streamHeader}}</h1>
            </div>

            <!--menu navigation-->
            <div class="topBar_menu">
              <div *ngIf="loginButtonActive" class="topBar_menu--item">
                <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                </div>
                <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                  REGISTER
                </div>
                <!-- <div class="pt-2 pb-2 topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                </div> -->
                <div class=" topBar_menu--item1 cursor-pointer" (click)="openSupportModal()">SUPPORT
                </div>
                <div class="btn-group ml-3" dropdown *ngIf="isUserLogin">
                  <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                    aria-controls="dropdown-autoclose1">
                    <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                  </div>
                  <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-autoclose1">
                    <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                      <span class="dropdown-item">
                        My Profile
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                      <span class="dropdown-item">
                        Payment History
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Swigits
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Purchases
                      </span>
                    </li>
                    <!-- <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="resendTicketCode()">
                                                    Re-send Code
                                                </span>
                                            </li> -->
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                      <span class="dropdown-item" (click)="onLogout()">
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>
      </header>

      <!-- body of website -->
      <section class="bodyForSite" style="padding: 2rem 0 2rem 0;{{contentBackgroundStyle}}">
        <div style="width: 100%; text-align: center; background-color: #000000d9;">
          <div style="width: 90%; display: inline-block; margin: 0 auto; padding: 2rem 0 2rem 0;">
            <!--wrapper-->
            <div class="contentWrapper">
              <!--image-->
              <div class="bodyForSite_image">
                <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster"
                  class="bodyForSite--poster">
              </div>

              <!--information-->
              <div class="bodyForSite_information" style="border: 1px solid #00a2ff;">

                <!--title-->
                <div class="small_title">
                  <h2>
                    {{streamDetailsFull.stream_design.text_above_buttons}}
                  </h2>
                </div>

                <!--buttons-->
                <div class="content--information">


                  <button type="button" class="watchButton cursor-pointer" *ngIf="!promoLinkActive"
                    (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                    <img src="../../assets/watch_promo.png" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>
                  <button type="button" class="watchButton cursor-pointer" *ngIf="promoLinkActive"
                    (click)="openLink()"><img src="../../assets/watch_promo.png" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>

                  <button class="watchButton" (click)="onSYVButtonClick('L')">
                    <img src="../../assets/watch_voice.svg" class="buttonIcon">
                    SHARE YOUR VOICE
                  </button>

                  <button type="button" class="watchButton cursor-pointer" *ngIf="channelButtonActive"
                    (click)="onChannelButtonClick()">
                    <img src="../../assets/watch_live.svg" class="buttonIcon2">
                    {{streamDetailsFull.stream_design.channel_video_btn_text}}</button>

                </div>
                <div class="content--information" *ngIf="paymentButtonActive">

                  <button type="button" class="watchButton cursor-pointer" *ngIf="extraButtonActive"
                    (click)="onExtraButtonClick()">{{streamDetailsFull.stream_design.extra_video_btn_text}}</button>



                  <button type="button" class="desButton" (click)="onDonatePerViewClick()" [disabled]="isPaymentDisable"
                    *ngIf="paymentButtonActive"
                    style="background: url(./assets/buyticket-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                    [ngStyle]="{'cursor': isPaymentDisable ? 'not-allowed' : 'pointer'}">
                    <!--{{streamDetailsFull.stream_design.payment_btn_text}}-->
                  </button>


                  <div *ngIf="watcheventButtonActive">
                    <button *ngIf="!viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_accesscode_btn_text}}-->
                    </button>
                    <button *ngIf="viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_btn_text}}-->
                    </button>
                  </div>

                </div>

                <!--description-->
                <div class="content--information2" style="text-align: left;" [innerHTML]="streamDescFull">

                </div>
                <div class="content--border" style="text-align: left;">

                </div>


                <!--social network sharing-->
                <div class="content--information_social" style="text-align: left;">
                  <h2>
                    Share on social network
                  </h2>
                  <div class="content_position">

                    <iframe src="https://c2ctv.swigit.com/sharethisframe.html" style="border: none; overflow: hidden;"
                      width="100%" height="80px"></iframe>
                  </div>
                </div>
                <div class="content--information_social2">
                  <button class="watchButton2" (click)="openLinkAll('https://www.chords2cure.org/donate-us')">
                    DONATE
                  </button>
                  <button class="watchButton3" (click)="openLinkAll('https://c2ctv.swigit.com/app/c2ctv')">
                    HOW TO SHARE YOUR VOICE
                  </button>
                  <button class="watchButton2" (click)="openLinkAll('https://www.chords2cure.org/c2c-merch')">
                    MERCH & MORE
                  </button>
                  <button class="watchButton3" (click)="openLinkAll('https://www.chords2cure.org/')">
                    FOUNDATION
                  </button>
                </div>
                <!--QR code-->
                <div class="QRcode">

                  <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- footer for website -->
      <section class="siteFooter"
        style=" border-top: #0068a5 1px solid;width: 100%; background-color:{{streamDetailsFull.stream_design.footer_background_color}}; ">

        <div class="siteFooter_logos" style=" align-items: center; width: 100%;">

          <a href="{{streamDetailsFull.stream_design.stream_android_app_url}}" *ngIf="androidAppAvailable"
            target="_blank"><img src="../../assets/googleplay_logo2.svg" class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_ios_app_url}}" *ngIf="iosAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/appleTV_logo2.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_firetv_app_url}}" *ngIf="firetvAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/amazonFire_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_roku_app_url}}" *ngIf="rokuAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/roku_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_pzaz_app_url}}" *ngIf="pzazAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/Pzaz_logo-20.png"
              class="footer--logo"></a>
        </div>

       

      </section>
    </div>
    <!--    C2CTVLIVE LAYOUT    -->
    <div style="width: 100%;" *ngIf="layoutFour">
      <!-- header of website -->
      <header>
        <section  style="padding: 0px !important; ">
          <!--wrapper-->
          <div class="topBar">

            <!--Logo-->
            <div class="topBar_space">
              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                  src="{{streamDetailsFull.stream_logo}}" class="topBar--logo"></a>

            </div>

            <!--title-->
            <div class="topBar--title">
              <h1> {{streamDetailsFull.stream_design.streamHeader}}</h1>
            </div>

            <!--menu navigation-->
            <div class="topBar_menu">
              <div *ngIf="loginButtonActive" class="topBar_menu--item">
                <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                </div>
                <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                  REGISTER
                </div>
                <!-- <div class="pt-2 pb-2 topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                </div> -->
                <div class=" topBar_menu--item1 cursor-pointer" (click)="openSupportModal()">SUPPORT
                </div>
                <div class="btn-group ml-3" dropdown *ngIf="isUserLogin">
                  <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                    aria-controls="dropdown-autoclose1">
                    <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                  </div>
                  <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-autoclose1">
                    <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                      <span class="dropdown-item">
                        My Profile
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                      <span class="dropdown-item">
                        Payment History
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Swigits
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Purchases
                      </span>
                    </li>
                    <!-- <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="resendTicketCode()">
                                                    Re-send Code
                                                </span>
                                            </li> -->
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                      <span class="dropdown-item" (click)="onLogout()">
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>
      </header>

      <!-- body of website -->
      <section class="bodyForSite" style="padding: 2rem 0 2rem 0;{{contentBackgroundStyle}}">
        <div style="width: 100%; text-align: center; background-color: #000000d9;">
          <div style="width: 90%; display: inline-block; margin: 0 auto; padding: 2rem 0 2rem 0;">
            <!--wrapper-->
            <div class="contentWrapper">
              <!--image-->
              <div class="bodyForSite_image">
                <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster"
                  class="bodyForSite--poster">
              </div>

              <!--information-->
              <div class="bodyForSite_information" style="border: 1px solid #00a2ff;">

                <!--title-->
                <div class="small_title">
                  <h2>
                    {{streamDetailsFull.stream_design.text_above_buttons}}
                  </h2>
                </div>

                <!--buttons-->
                <div class="content--information">


                  <button type="button" class="watchButton cursor-pointer" *ngIf="!promoLinkActive"
                    (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                    <img src="../../assets/watch_promo.png" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>
                  <button type="button" class="watchButton cursor-pointer" *ngIf="promoLinkActive"
                    (click)="openLink()"><img src="../../assets/watch_promo.png" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>

                  <button class="watchButton" (click)="onSYVButtonClick('L')">
                    <img src="../../assets/watch_voice.svg" class="buttonIcon">
                    SHARE YOUR VOICE
                  </button>

                  <button type="button" class="watchButton cursor-pointer" *ngIf="channelButtonActive"
                    (click)="onChannelButtonClick()">
                    <img src="../../assets/watch_live.svg" class="buttonIcon2">
                    {{streamDetailsFull.stream_design.channel_video_btn_text}}</button>

                </div>
                <div class="content--information" *ngIf="paymentButtonActive">

                  <button type="button" class="watchButton cursor-pointer" *ngIf="extraButtonActive"
                    (click)="onExtraButtonClick()">{{streamDetailsFull.stream_design.extra_video_btn_text}}</button>



                  <button type="button" class="desButton" (click)="onDonatePerViewClick()" [disabled]="isPaymentDisable"
                    *ngIf="paymentButtonActive"
                    style="background: url(./assets/buyticket-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                    [ngStyle]="{'cursor': isPaymentDisable ? 'not-allowed' : 'pointer'}">
                    <!--{{streamDetailsFull.stream_design.payment_btn_text}}-->
                  </button>


                  <div *ngIf="watcheventButtonActive">
                    <button *ngIf="!viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_accesscode_btn_text}}-->
                    </button>
                    <button *ngIf="viewEventFlag" type="button" class="desButton" [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_btn_text}}-->
                    </button>
                  </div>

                </div>

                <!--description-->
                <div class="content--information2" style="text-align: left;" [innerHTML]="streamDescFull">

                </div>
                <div class="content--border" style="text-align: left;">

                </div>


                <!--social network sharing-->
                <div class="content--information_social" style="text-align: left;">
                  <h2>
                    Share on social network
                  </h2>
                  <div class="content_position">

                    <iframe src="https://c2ctv.swigit.com/sharethisframe2.html" style="border: none; overflow: hidden;"
                      width="100%" height="80px"></iframe>
                  </div>
                </div>
                <div class="content--information_social2">
                  <button class="watchButton2" (click)="openLinkAll('https://www.chords2cure.org/donate-us')">
                    DONATE
                  </button>
                  <button class="watchButton3" (click)="openLinkAll('https://c2ctv.swigit.com/app/c2ctvlive')">
                    HOW TO SHARE YOUR VOICE
                  </button>
                  <button class="watchButton2" (click)="openLinkAll('https://www.chords2cure.org/c2c-merch')">
                    MERCH & MORE
                  </button>
                  <button class="watchButton3" (click)="openLinkAll('https://www.chords2cure.org/')">
                    FOUNDATION
                  </button>
                </div>
                <!--QR code-->
                <div class="QRcode">

                  <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- footer for website -->
      <section class="siteFooter"
        style=" border-top: #0068a5 1px solid;width: 100%; background-color:{{streamDetailsFull.stream_design.footer_background_color}}; ">

        <div class="siteFooter_logos" style=" align-items: center; width: 60%;">

          <a href="{{streamDetailsFull.stream_design.stream_android_app_url}}" *ngIf="androidAppAvailable"
            target="_blank"><img src="../../assets/googleplay_logo2.svg" class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_ios_app_url}}" *ngIf="iosAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/appleTV_logo2.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_firetv_app_url}}" *ngIf="firetvAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/amazonFire_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_roku_app_url}}" *ngIf="rokuAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/roku_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_pzaz_app_url}}" *ngIf="pzazAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/Pzaz_logo-20.png"
              class="footer--logo"></a>
        </div>

        <div class="siteFooter_copyInfo" style="text-align: center; width: 35%;   ">
          {{streamDetailsFull.stream_design.copyright_text}} <br>
          {{streamDetailsFull.stream_design.footer_link_text}}
        </div>

      </section>
    </div>
    <div style="width: 100%;" *ngIf="layoutFive">
      <!-- header of website -->
      <header>
        <section  style="padding: 0px !important; ">
          <!--wrapper-->
          <div class="topBar">

            <!--Logo-->
            <div class="topBar_space" style="text-align: center;">
              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                  src="{{streamDetailsFull.stream_logo}}" class="topBar--logo" style="width: 100% !important;"></a>

            </div>

            <!--title-->
            <div class="topBar--title">
              <h1> {{streamDetailsFull.stream_design.streamHeader}}</h1>
            </div>

            <!--menu navigation-->
            <div class="topBar_menu">
              <div *ngIf="loginButtonActive" class="topBar_menu--item">
                <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                </div>
                <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                  REGISTER
                </div>
                <!-- <div class="pt-2 pb-2 topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                </div> -->
                <div class=" topBar_menu--item1 cursor-pointer" (click)="openSupportModal()">SUPPORT
                </div>
              </div>
              <div *ngIf="isUserLogin">
                <!-- <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;" (click)="resendTicketCode()"
                  *ngIf="resendButtonActive">

                  <span class="hidden-sm"> RE-SEND CODE </span>
                </div> -->
                <div class="btn-group ml-3" dropdown>
                  <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                    aria-controls="dropdown-autoclose1">
                    <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                  </div>
                  <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-autoclose1">
                    <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                      <span class="dropdown-item">
                        My Profile
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                      <span class="dropdown-item">
                        Payment History
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Swigits
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Purchases
                      </span>
                    </li>
                    <!-- <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="resendTicketCode()">
                                                    Re-send Code
                                                </span>
                                            </li> -->
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                      <span class="dropdown-item" (click)="onLogout()">
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--
              <div *ngIf="!isUserLogin" class="topBar_menu">
                  <div class="topBar_menu--item cursor-pointer" 
                    (click)="openLoginModal()" *ngIf="loginButtonActive">
                    
                    <span class="hidden-sm"> LOGIN </span>
              </div>
                  <div class="  topBar_menu--item cursor-pointer" 
                    (click)="openRegisterModal()" *ngIf="loginButtonActive">
                   
                    <span class="hidden-sm"> REGISTER </span>
            </div>
                  <div class=" topBar_menu--item cursor-pointer"
                     (click)="openSupportModal()">
                    
                    <span class="hidden-sm"> SUPPORT </span>
          </div>
                </div>
                
              <div *ngIf="isUserLogin" class="topBar_menu">
                  <div class="pt-2 pb-2 topBar_menu--item" style="cursor: pointer;"
                    (click)="resendTicketCode()" *ngIf="resendButtonActive">
                    
                    <span class="hidden-sm"> RE-SEND CODE </span>
              </div>
              <div class="pt-2 pb-2 topBar_menu--item" style="cursor: pointer;"
                   *ngIf="!resendButtonActive">
                    
                    <span class="hidden-sm"> &nbsp;</span>
              </div>
                  <div class="pt-2 pb-2 topBar_menu--item cursor-pointer" 
                    (click)="onLogout()" *ngIf="loginButtonActive">
                    
                    <span class="hidden-sm"> LOGOUT </span>
            </div>
                  <div class="pt-2 pb-2 topBar_menu--item cursor-pointer"
                     (click)="openSupportModal()">
                    
                    <span class="hidden-sm"> SUPPORT </span>
          </div>
                </div>

              -->
          </div>
        </section>
      </header>

      <!-- body of website -->
      <section class="bodyForSite" style="{{contentBackgroundStyle}}padding: 0px !important;">
        <div
          style="width: 100%; text-align: center; padding: 2rem 0 2rem 0; background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5));">
          <div style=" ">
            <!--wrapper-->
            <div class="contentWrapper">
              <!--image-->
              <div class="bodyForSite_image">
                <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster"
                  class="bodyForSite--poster">
              </div>

              <!--information-->
              <div class="bodyForSite_information">

                <!--title-->
                <div class="small_title">
                  <h2 style="font-size: 3rem; font-weight: 400;">
                    {{streamDetailsFull.stream_design.text_above_buttons}}
                  </h2>
                </div>

                <!--buttons-->
                <div class="content--information">


                  <button type="button" class="watchButton cursor-pointer orangeButton" *ngIf="!promoLinkActive"
                    (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>
                  <button type="button" class="watchButton cursor-pointer orangeButton" *ngIf="promoLinkActive"
                    (click)="openLink()"><img src="../../assets/watch_promo.png" class="buttonIcon">

                    {{streamDetailsFull.stream_design.promo_btn_text}}</button>

                  <button type="button" class="watchButton cursor-pointer  orangeButton"
                    (click)="onDonatePerViewClick()" [disabled]="isPaymentDisable" *ngIf="paymentButtonActive"
                    [ngStyle]="{'cursor': isPaymentDisable ? 'not-allowed' : 'pointer'}">
                    BUY TICKET <BR> (Donate to view)
                  </button>

                  <!--button *ngIf="!viewEventFlag" type="button" class="watchButton cursor-pointer  orangeButton" [disabled]="isWatchEventDisable"
                        [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                 
                        (click)="onWatchWithTCClick()">
                        WATCH
                      </button-->

                  <button type="button" class="watchButton cursor-pointer  orangeButton"
                    (click)="openLinkAll('https://app.beem.xyz/e/chords2cure-under-the-stars-concert_9406')">
                    WATCH EVENT
                  </button>

                  <a href="../../assets/How-to-watch-on-Beem.pdf" download style=""><button type="button"
                      class="watchButton cursor-pointer orangeButton" style="text-decoration: none;">
                      Download
                      instructions to Watch Event</button></a>

                  <!-- (click)="onWatchWithTCClick()" -->
                  <!--button type="button" class="watchButton cursor-pointer orangeButton" *ngIf="channelButtonActive"
                        (click)="onChannelButtonClick()">
                        
                        WATCH WITH CHAT</button-->



                </div>
                <div class="" *ngIf="paymentButtonActive">

                  <button type="button" class="watchButton cursor-pointer  orangeButton" *ngIf="extraButtonActive"
                    (click)="onExtraButtonClick()">{{streamDetailsFull.stream_design.extra_video_btn_text}}</button>






                  <div *ngIf="watcheventButtonActive">
                    <button *ngIf="!viewEventFlag" type="button" class="desButton  orangeButton"
                      [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_accesscode_btn_text}}-->
                    </button>
                    <button *ngIf="viewEventFlag" type="button" class="desButton  orangeButton"
                      [disabled]="isWatchEventDisable"
                      [ngStyle]="{'cursor': isWatchEventDisable ? 'not-allowed' : 'pointer'}"
                      style="background: url(./assets/watchwithticketcode-btn.png) no-repeat; background-size:  100% 100%; height: 42px !important; background-position: center;"
                      (click)="onWatchWithTCClick()">
                      <!--{{streamDetailsFull.stream_design.watch_event_btn_text}}-->
                    </button>
                  </div>

                </div>

                <!--description-->
                <div class="content--information2" style="text-align: left; line-height: 1.2 !important;">

                  LIVE STREAMING from Bergamot Cafe in Santa Monica, CA<br>
                  <br>
                  <span style="font-size: 2.5rem; font-weight: 400;">CHORDS2CURE UNDER THE STARS</span><br>
                  May 1, 2022, 7:00 PM PDT. <br>
                  <br>
                  Please join us for a memorable evening of outstanding performances featuring our teen cast who are
                  contributing their talents to help raise much needed funds for groundbreaking Pediatric Cancer
                  Research. <br>
                  <br>
                  For this show, we are honoring longtime cast member (and friend), Ana Kahan (21), whose riveting
                  vocals have been a highlight at Chords2Cure for years. At the height of COVID, a tumor was found in
                  Ana’s chest wall and she was diagnosed with Thymoma, a cancer linked with an autoimmune disease called
                  Myasthenia Gravis. She endured 2 grueling surgeries + proton therapy radiation. We are happy to report
                  that Ana is now CANCER-FREE though her autoimmune disease will be a life-long condition. Although her
                  vocal cords were temporarily paralyzed during surgery, Ana is finally able to sing again and she will
                  take the stage once again on May 1st!<br>
                  <br>
                  All funds raised by Chords2Cure go to critical research at the UCLA Jonsson Comprehensive Cancer
                  Center where Ana (and Chords2Cure student co-founders, Mafalda & Jaxon) received life-saving
                  treatment. <br>
                  <br>
                  Please join us in curing Pediatric Cancer…one chord at a time!<br>
                  <br>
                  CHORDS2CURE, IS A STUDENT-CENTRIC, 501(C)3 TAX-EXEMPT ORGANIZATION
                </div>



                <!--social network sharing-->
                <div class="content--information_social" style="text-align: left;border-top-style: solid;
              border-top-width: 2px;
              border-top-color: #9ed4ed;">
                  <h2>
                    Share on social network
                  </h2>
                  <div class="content_position">

                    <iframe src="https://c2ctv.swigit.com/sharethisframe3.html" style="border: none; overflow: hidden;"
                      width="100%" height="70px"></iframe>
                  </div>
                </div>
                <div class="content--information_social2">
                  <button class="watchButton2" (click)="openLinkAll('https://www.chords2cure.org/donate-us')">
                    DONATE
                  </button>
                  <button class="watchButton3" (click)="openLinkAll('https://c2ctv.swigit.com/app/c2ctv')">
                    HOW TO SHARE YOUR VOICE
                  </button>

                </div>
                <div class="content--information_social2 border_bot5">
                  <button class="watchButton2" (click)="openLinkAll('https://www.chords2cure.org/c2c-merch')">
                    MERCH & MORE
                  </button>
                  <button class="watchButton3" (click)="openLinkAll('https://www.chords2cure.org/')">
                    FOUNDATION
                  </button>
                </div>


                <!--QR code-->
                <div class="QRcode" style="text-align: left;border-top-style: solid;
              border-top-width: 2px;
              border-top-color: #9ed4ed;">

                  <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                </div>
                <!-- swig and beem logo -->
                <div class="content_position center_logo_position">
                  <img src="../../assets/swig_logo.png" alt="swig logo" class="socialIcon logo_size_margin">
                  <img src="../../assets/beem_logo.png" alt="swig logo"
                    class="socialIcon logo_size_margin logo_size_small">
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- footer for website -->
      <section class="siteFooter"
        style="width: 100%; background-color:{{streamDetailsFull.stream_design.footer_background_color}}; ">

        <div class="col-lg-7 col-sm-12 siteFooter_logos" style="width: 60%; float: left; ">

          <a href="{{streamDetailsFull.stream_design.stream_android_app_url}}" *ngIf="androidAppAvailable"
            target="_blank"><img src="../../assets/googleplay_logo.svg" class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_ios_app_url}}" *ngIf="iosAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/appleTV_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_firetv_app_url}}" *ngIf="firetvAppAvailable"
            style="padding-left: 10px;" target="_blank"><img src="../../assets/amazonFire_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_roku_app_url}}" *ngIf="rokuAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/roku_logo.svg"
              class="footer--logo"></a>
          <a href="{{streamDetailsFull.stream_design.stream_pzaz_app_url}}" *ngIf="pzazAppAvailable"
            class="footer--logo" style="padding-left: 10px;" target="_blank"><img src="../../assets/Pzaz_logo-20.png"
              class="footer--logo"></a>
        </div>

        <div class="col-lg-3 col-sm-12  siteFooter_copyInfo" style="text-align: center; width: 35%;   ">
          {{streamDetailsFull.stream_design.copyright_text}} <br>
          {{streamDetailsFull.stream_design.footer_link_text}}
        </div>
        <div class="col-lg-2 col-sm-12 footer_3"
          style=" background-color: #e3331b; text-align: center; height: 10rem; padding-top: 2rem;">

          <a href="../../assets/TV-Instructions-Celebration.pdf" download style="font-family: 'Tw Cen MT Condensed'; font-size: 34px;color: white; text-shadow: 2px 2px 2px #252525; 
        ">How to Watch on TV</a>

        </div>
      </section>
    </div>
    <div style="width: 100%;" *ngIf="layoutSix">

      <!-- header of website -->
      <header>
        <section  style="padding: 0px !important; ">
          <!--wrapper-->
          <div class="topBar" style="border-bottom: none !important">
            <!--Logo-->
            <div class="topBar_space">
              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                  src="{{streamDetailsFull.stream_logo}}" class="topBar--logo"></a>

            </div>

            <!--title-->
            <div class="topBar--title">
              <h1 class="smallH1"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
              <h2>
                {{streamDetailsFull.stream_design.stream_header_tagline}}
              </h2>
            </div>

            <!--menu navigation-->
            <div class="topBar_menu">
              <div *ngIf="loginButtonActive" class="topBar_menu--item">
                <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                </div>
                <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                  REGISTER
                </div>
                <!-- <div class="pt-2 pb-2 topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                </div> -->
                <div class=" topBar_menu--item1 cursor-pointer" (click)="openSupportModal()">SUPPORT
                </div>
              </div>
              <div *ngIf="isUserLogin">
                <!-- <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;" (click)="resendTicketCode()"
                  *ngIf="resendButtonActive">

                  <span class="hidden-sm"> RE-SEND CODE </span>
                </div> -->
                <div class="btn-group ml-3" dropdown>
                  <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle
                    aria-controls="dropdown-autoclose1">
                    <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                  </div>
                  <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-autoclose1">
                    <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                      <span class="dropdown-item">
                        My Profile
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                      <span class="dropdown-item">
                        Payment History
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Swigits
                      </span>
                    </li>
                    <li role="menuitem" class="cursor-pointer">
                      <span class="dropdown-item">
                        My Purchases
                      </span>
                    </li>
                    <!-- <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="resendTicketCode()">
                                                    Re-send Code
                                                </span>
                                            </li> -->
                    <li class="divider dropdown-divider"></li>
                    <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                      <span class="dropdown-item" (click)="onLogout()">
                        Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>
      </header>

      <!-- Body for site -->
      <div class="bodyContainer" style="padding: 3rem; {{contentBackgroundStyle}}">

        <!-- main content -->

        <div style="padding-left: 1.5rem !important; background-color: transparent !important;"
          class="bodyContainer_main">
          <!-- poster -->
          <div class="bodyContainer--poster">
            <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster"
              class="poster_image border_box--image">
          </div>

          <!-- information block -->
          <div class="bodyContainer--information" style="padding: 0 !important;">

            <!-- buttons -->
            <div class="bodyContainer_buttonsBig border_box"
              style="  justify-content: center !important; padding: 0.5rem !important; ">
              <button class="watchButton6">
                <img src="../../assets/trailer.svg" class="buttonIcon" />
                TRAILER
              </button>
              <button class="watchButton6">
                <img src="../../assets/watch_promo.svg" class="buttonIcon" />
                DONATE TO VIEW
              </button>
              <button class="watchButton6">
                <img src="../../assets/watch_live.svg" class="buttonIcon2" />
                WATCH WITH TICKET CODE
              </button>
              <button class="watchButton6">
                <img src="../../assets/program_description.svg" class="buttonIcon2" />
                PROGRAM DESCRIPTION
              </button>
            </div>

            <!-- text before actors -->
            <div class="text_actors2">
              <div class="border_box spacing_top">
                <p class="text_actors_i" style="font-size: 1.8rem  !important; line-height: 1 !important;">
                  Hosted by Kari Howard and Michael Orland<br />
                  Featuring Memorable Performances by:<br /><br />
                  Valerie Perri, Jason Graae, Alisan Porter, David Burnham Robert
                  Yacko, Jenna Rosen, Sam Gravitte, Kari Howard and Special Guest
                  Particia Ward Kelly<br /><br />
                  Musical Director/Pianist: Michael Orland<br /><br />
                  Producer/Director: Ivan Gulas<br /><br />
                </p>
              </div>

              <div class="border_box text_actors_i spacing_top">
                <h2>Share on social networks</h2>
                <!--social icons-->
                <div class="content_position">
                  <iframe src="https://celebration.swigit.com/sharethisframe.html"
                    style="border: none; overflow: hidden;" width="95%" height="100px"></iframe>
                </div>

                <div class="QRCodeClass" style=" ">
                  <qrcode [qrdata]="myAngularxQrCode" [width]="100" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'award_pictureQR'"></qrcode>
                </div>
              </div>
            </div>
            <!-- awards -->
            <div class="awards border_box spacing_top" style="height: 200px;  text-align: center; ">


              <img *ngFor="let links of this.streamDetailsFull.stream_links;" src="{{links.link_image_path}}"
                class="award_picture">


            </div>


            <div class="awards border_box spacing_top">
              <h2 style="padding: 1rem;">
                100% of the profits will be donated to Ukrainian assistance.
              </h2>
            </div>
          </div>
        </div>

        <!-- cast section -->
        <div class="bodyContainer_social border_box">
          <h2 style="margin-top: 4px;">
            Cast:
          </h2>
          <!-- pictures -->
          <div class="actors_pictures">
            <img src="../../assets/actor_1.png" alt="Kari Howard" class="pictureA" />
            <img src="../../assets/actor_2.png" alt="Michael Orland" class="pictureA" />
            <img src="../../assets/actor_3.png" alt="Jason Graae" class="pictureA" />
            <img src="../../assets/actor_4.png" alt="Jenna Lea Rosen" class="pictureA" />
            <img src="../../assets/actor_5.png" alt="Valerie Perri" class="pictureA" />
            <img src="../../assets/actor_6.png" alt="Robert Yacko" class="pictureA" />
            <img src="../../assets/actor_7.png" alt="David Burnham" class="pictureA" />
            <img src="../../assets/actor_8.png" alt="Sam Gravitte" class="pictureA" />
            <img src="../../assets/actor_9.png" alt="Alisan Porter" class="pictureA" />
            <img src="../../assets/actor_10.png" alt="Kimberley Locke" class="pictureA" />
          </div>
        </div>
      </div>

      <!-- Footer of site -->
      <div class="siteFooter">
        <!-- copyright information -->
        <div class="siteFooter_copyInfo">
          SWIGIT&trade; swigit.com <br />
          info@swigmedia.com
        </div>

        <!-- how to watch on TV -->
        <div class="siteFooter_TV">
          <p class="siteFooter_TV--watch">HOW TO WATCH ON YOUR TV</p>
        </div>
      </div>
    </div>
    <div style="width: 100%;" *ngIf="layoutSeven">

      <!-- header of website -->
      <header>
        <section style="padding: 0px !important; ">
          <!--wrapper-->
          <div class="topBar" style="border-bottom: none !important">
            <!--Logo-->
            <div class="topBar_space">
              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                  src="{{streamDetailsFull.stream_logo}}" class="topBar--logobig"></a>

            </div>

            <!--title-->
            <div class="topBar--title">
              <h1 class="smallH1"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
              <h1 class="smallH1">
                {{streamDetailsFull.stream_design.stream_header_tagline}}
              </h1>
            </div>

            <!--menu navigation-->
            <div class="topBar_menu">
              <div style="text-align: center; "><a href="https://swigit.com/" target="_blank"><img
                    src="https://swigappmanager.com/uploads/stream_images/swigit-logo3.png" style="width: 20rem;"></a>
              </div>
              <div *ngIf="loginButtonActive" class="topBar_menu--item">
                <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                </div>
                <!-- <div class="topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                </div> -->
                <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                  REGISTER
                </div>

                <div class=" topBar_menu--item1 cursor-pointer"  *ngIf="!isUserLogin" (click)="openSupportModal()">SUPPORT
                </div>
              </div>

              <div class="btn-group " dropdown *ngIf="isUserLogin" style="width: 100%;     text-align: center; padding-top: 3px;">
                <div class=" dropdown-toggle" style=" width: 100%;     text-align: center;" id="button-autoclose1" dropdownToggle
                  aria-controls="dropdown-autoclose1">
                  <i class="fa fa-user-circle fa-2x cursor-pointer" aria-hidden="true"></i>
                </div>
                <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                  aria-labelledby="button-autoclose1">
                  <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                    <span class="dropdown-item">
                      My Profile
                    </span>
                  </li>
                  <!--li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                    <span class="dropdown-item">
                      Payment History
                    </span>
                  </li>
                  <li role="menuitem" class="cursor-pointer">
                    <span class="dropdown-item">
                      My Swigits
                    </span>
                  </li>
                  <li role="menuitem" class="cursor-pointer">
                    <span class="dropdown-item">
                      My Purchases
                    </span>
                  </li-->
                  <li role="menuitem" class="cursor-pointer" (click)="openSupportModal()">
                    <span class="dropdown-item">
                      Support
                    </span>
                  </li>
                  <!-- <li role="menuitem" class="cursor-pointer">
                                              <span class="dropdown-item" (click)="resendTicketCode()">
                                                  Re-send Code
                                              </span>
                                          </li> -->
                  <li class="divider dropdown-divider"></li>
                  <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                    <span class="dropdown-item" (click)="onLogout()">
                      Logout
                    </span>
                  </li>
                </ul>
              </div>
              <!-- div *ngIf="isUserLogin" >
         <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;"
           (click)="resendTicketCode()" *ngIf="resendButtonActive">
           
           <span class="hidden-sm"> RE-SEND CODE </span>
         </div>
       </div -->
            </div>

          </div>
        </section>
      </header>

      <!-- Body for site -->
      <!--div class="bodyContainer" style="padding: 4rem; {{contentBackgroundStyle}}"-->
      <div class="bodyContainer" style="background: url('https://swigappmanager.com/images/ride4kind_background.png'); background-repeat: no-repeat;
  background-size: 100% 100%;  ">
        <!-- main content -->

        <div style=" background-color: transparent !important;" class="bodyContainer_main">
          <!-- poster -->
          <div class="bodyContainer--poster">
            <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster" class="poster_image ">
          </div>

          <!-- information block -->
          <div class="bodyContainer--information" style="padding: 0 !important;">

            <!-- buttons -->


            <!-- text before actors -->
            <div class="text_actors2">
              <div class="bodyContainer_buttonsBig "
                style="  justify-content: center !important; padding: 2rem !important; ">
                <button class="watchButton6" (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                  <!--img src="../../assets/ trailer.svg" class="buttonIcon" /-->
                  PROMO
                </button>
                <button class="watchButton6" (click)="openLinkAll('https://forms.gle/DhALiAt5LHr4gCXR6')">
                  <!--img
              src="../../assets/program_description.svg"
              class="buttonIcon2"
            /-->
                  PARTICIPATE
                </button>

                <button class="watchButton6" (click)="alertMessageClick()">
                  <!--img src="../../assets/watch_live.svg" class="buttonIcon2" /-->
                  WATCH
                </button>
                <button class="watchButton6" (click)="alertMessageClick()">
                  <!--img src="../../assets/watch_promo.svg" class="buttonIcon" /-->
                  DONATE
                </button>

              </div>
              <div class=" spacing_top" style=" text-align: center;padding: 5px 90px;">
                <span class="text_actors_i"
                  style="text-align: center; font-weight: 300; line-height: 0.5rem !important;"
                  [innerHTML]="streamDescFull"></span>
              </div>
              <div class="bodyContainer_buttonsBig "
                style="  justify-content: center !important; padding: 2rem !important; ">
                <button class="watchButton7" (click)="openRideKindItinerary()">
                  <!--img src="../../assets/trailer.svg" class="buttonIcon" /-->
                  RIDE ITINERARY
                </button>
                <button class="watchButton7" (click)="openRideKindInfoModal()">
                  <!--img src="../../assets/watch_promo.svg" class="buttonIcon" /-->
                  OTHER KINDNESS EVENTS
                </button>

              </div>

              <div class=" text_actors_i spacing_top" style="text-align: center;">
                <hr style="border-top: 0.1rem solid #00a2ff; width: 85%; margin: auto;">
                <h2 style="padding-top: 10px;" class="h2_class">Share on social networks</h2>
                <!--social icons-->
                <div class="content_position" style="text-align:center;">
                  <iframe src="https://ride4kindness.swigit.com/sharethisframe3.html"
                    style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
                </div>


              </div>
            </div>


          </div>
        </div>


      </div>

      <!-- Footer of site -->
      <div class="siteFooter">
        <!-- copyright information -->
        <div class="siteFooter_copyInfo">
          <!-- awards -->
          <!--div  class="awards  spacing_top" style=" text-align: center; ">
      <img  *ngFor="let links of this.streamDetailsFull.stream_links;" src="{{links.link_image_path}}"   class="award_picture">
    </div-->


          <div *ngFor="let links of this.streamDetailsFull.stream_links;" style="float: left;">

            <a *ngIf="links.link_type == 'TL'" href="{{links.link_url}}" class="award_picture"
              target="_blank">{{links.link_text}}</a>

            <a *ngIf="links.link_type == 'IL'" href="{{links.link_url}}" target="_blank"><img
                src="{{links.link_image_path}}" class="award_picture" style="height: 6rem !important;"></a>

            <img *ngIf="links.link_type == 'IO'" src="{{links.link_image_path}}" class="award_picture" style="height: 6rem !important;">


            <span *ngIf="links.link_type == 'TO'" class="award_picture">{{links.only_text}}</span>

          </div>
          <!--qrcode [qrdata]="myAngularxQrCode" [width]="100" [errorCorrectionLevel]="'M'" [colorLight]="'#d9d9d9'"
            [cssClass]="'award_pictureQR'"></qrcode-->
        </div>

        <!-- how to watch on TV -->
        <div class="siteFooter_TV">
          <p class="siteFooter_TV--watch"
            style="width: 300px; text-align: right !important; padding-right: 15px; padding-left: 10px">Check out our
            <br>
            Augmented Reality<br>
            Promotion!<br>
            
            <span style="font-size: 2rem;">SCAN <i class="fa fa-arrow-right" aria-hidden="true"></i></span><br>
            <a href="https://arurl.co/q4yr9v" target="_blank" style="color: white;">https://arurl.co/q4yr9v</a>
            
          </p>

          <a href="https://arurl.co/q4yr9v" target="_blank"><img src="https://swigappmanager.com/uploads/stream_images/ride-qr.png"
              style="height: 11rem; padding: 5px 10px"></a>
        </div>
      </div>
    </div>
    <div style="width: 100%;" *ngIf="layoutEight">

     
  
      <!-- Start Mobile Header -->
      <header class="mobile-header">
          <div class="mobile-header-content">
              <div class="container">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="mobile-logo text-center">
                             
                              <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                                src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                          </div>
                      </div>
                      <!-- Logo Col End -->
                  </div>
                  <!-- Row End -->
              </div>
              <!-- Container End -->
          </div>
          <!-- Header Content End -->
      </header>
      <!-- Mobile Header End -->
      <!-- Start Header -->
      <header class="main-nav clearfix is-sticky">
          <div class="container-fluid">
              <div class="row justify-content-between" style="padding: 10px 0px;">
                  <div class="col-md-3 pl-0  col-sm-12">
                      <!-- Start Navigation -->
                      <nav class="navbar navbar-expand-lg">
                          
                          <a class="navbar-brand" href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                            src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                          <!-- Logo End -->
                          
                          
                      </nav>
                      <!-- Navigation End -->
                  </div>
                  <div class="col-md-7  col-sm-12" >
                    <h1 class="smallH1" style="color: red !important;"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
                    <h1 class="smallH2" style="color: white !important; font-size: 14px;">
                      {{streamDetailsFull.stream_design.stream_header_tagline}}
                    </h1>
                  </div>
                  <!-- Col End -->
                  <div class="col-md-2 text-right  col-sm-12">
                    <div class="topBar_menu">
                      <div style="text-align: center; "><a href="https://swigit.com/" target="_blank"><img
                            src="https://swigappmanager.com/uploads/stream_images/swigit-logo3.png" style="width: 20rem;"></a>
                      </div>
                      <div *ngIf="loginButtonActive" class="topBar_menu--item">
                        <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                        </div>
                        <!-- <div class="topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                        </div> -->
                        <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                          REGISTER
                        </div>
        
                        <div class=" topBar_menu--item1 cursor-pointer"  *ngIf="!isUserLogin" (click)="openSupportModal()">SUPPORT
                        </div>
                      </div>
        
                      <div class="btn-group " dropdown *ngIf="isUserLogin" style="width: 100%;     text-align: center; padding-top: 3px;">
                        <div class=" dropdown-toggle" style=" width: 100%;     text-align: center;" id="button-autoclose1" dropdownToggle
                          aria-controls="dropdown-autoclose1">
                          <i class="fa fa-user-circle fa-2x cursor-pointer" style="color: red;" aria-hidden="true"></i>
                        </div>
                        <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                          aria-labelledby="button-autoclose1">
                          <!--li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                            <span class="dropdown-item">
                              My Profile
                            </span>
                          </li-->
                          <!--li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                            <span class="dropdown-item">
                              Payment History
                            </span>
                          </li>
                          <li role="menuitem" class="cursor-pointer">
                            <span class="dropdown-item">
                              My Swigits
                            </span>
                          </li>
                          <li role="menuitem" class="cursor-pointer">
                            <span class="dropdown-item">
                              My Purchases
                            </span>
                          </li-->
                          <li role="menuitem" class="cursor-pointer" (click)="openSupportModal()">
                            <span class="dropdown-item">
                              Support
                            </span>
                          </li>
                          <!-- <li role="menuitem" class="cursor-pointer">
                                                      <span class="dropdown-item" (click)="resendTicketCode()">
                                                          Re-send Code
                                                      </span>
                                                  </li> -->
                          <li class="divider dropdown-divider"></li>
                          <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                            <span class="dropdown-item" (click)="onLogout()">
                              Logout
                            </span>
                          </li>
                        </ul>
                      </div>
                      <!-- div *ngIf="isUserLogin" >
                 <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;"
                   (click)="resendTicketCode()" *ngIf="resendButtonActive">
                   
                   <span class="hidden-sm"> RE-SEND CODE </span>
                 </div>
               </div -->
                    </div>
                  </div>
                  <!-- Col End -->
              </div>
              <!-- Row End -->
          </div>
          <!-- Container End -->
      </header>
      <!-- Header End -->
      
      <!-- Start Main Content -->
      <div class="main-content" style="background: url({{streamDetailsFull.stream_design.content_background_image_url}}); background-repeat: no-repeat;
      background-size: 100% 100%; padding: 0px; ">
      <div style="background-color: rgb(52 58 64 / 78%); ">
          <!-- Start Play Details Section -->
          <section class="play-details">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-md-3  col-sm-12">
                          <div class="row">
                              <div class="col-md-12" style="padding-left: 0px !important; padding-right: 0px !important;">
                                  <div class="play-thumb mb-4">
                                      <img class="img-fluid" src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="">
                                      
                                  </div>
                                 
                              </div>
                              <!-- Col End -->
                          </div>
                          <!-- Row End -->
                      </div>
                      <!-- Col End -->
                      <div class="col-md-9  col-sm-12">
                          <div class="play-details-content">
                              <div class="title-block d-flex align-items-center justify-content-between">
                                  <h2 class="play-title">{{streamDetailsFull.stream_title}}</h2>
                              </div>
                              <!-- Title Block -->
                              <div class="details-info mb-4">
                                  <span *ngIf="streamDetailsFull.stream_certi"><i class="icofont-user mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_certi}}</span>
                                  <span *ngIf="streamDetailsFull.stream_dur_str"><i class="icofont-clock-time mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_dur_str}}</span>
                                  <span *ngIf="streamDetailsFull.stream_year"><i class="icofont-simple-smile mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_year}}</span>
                                  <span *ngIf="streamDetailsFull.stream_genre"><i class="icofont-movie mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_genre}}</span>
                                  
                              </div>
                              <!-- Details Info -->
                              <div class="details-desc">
                                  <p [innerHTML]="streamDescFull"></p>
                              </div>
                              
                              
                              <!-- Movie Persons -->
                              <div class="details-buttons">
                                  <div class="row d-flex align-items-center">
                                      <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                        
                                          <a  (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                            <i class="icofont-ui-movie mr-2" aria-hidden="true"></i>PROMO</a>
                                      </div>
                                      <!-- Col End -->
                                      <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                       
                                        <a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                          <i class="icofont-ui-play mr-2" aria-hidden="true"></i>WATCH</a>
  
                                      </div>
                                      
                                      <div class="col-md-3 col-xl  mb-3 col-sm-12 d-none d-sm-block">
                                       &nbsp;
                                      </div>
                                      <div class="col-md-3 col-xl mb-3  col-sm-12">
                                        
                                        <!--a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                          <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a-->

                                        <a (click)="openLinkAll('https://buy.stripe.com/3cs28Xd9T5FLbYsaEF')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                            <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a>
                                          
                                      </div>
                                      
                                     
                                  </div>

                                  <div class="row d-flex align-items-center">
                                    <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                     
                      
                                      <a (click)="openRideKindItinerary()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                        <i class="icofont-road mr-2" aria-hidden="true"></i>RIDE ITINERARY</a>
                                  </div>
                                    <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                       
                        
                                      <a (click)="openLinkAll('https://forms.gle/DhALiAt5LHr4gCXR6')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                        <i class="icofont-plus mr-2" aria-hidden="true"></i>PARTICIPATE</a>
                                    </div>
                                    <!-- Col End -->
                                    <div class="col-md-3 col-xl  mb-3 col-sm-12  d-none d-sm-block">
                                      &nbsp;
                                    </div>
                                    <!-- Col End -->
                                    <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                     
                                      <a (click)="openRideKindInfoModal()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                        <i class="icofont-children-care mr-2" aria-hidden="true"></i>OTHER KINDNESS EVENTS</a>

                                    </div>
                                    
                                </div>
                                  <!-- Row End -->
                              </div>
                              <!-- Details Buttons End -->
                              <div class="details-desc" style="text-align: center;">
                                
                                <h2 style="padding-top: 20px; font-family: 'Roboto', sans-serif !important; color: white !important; font-weight: bold;" class="h2_class" >Share on social networks</h2>
                                <!--social icons-->
                                <div class="content_position" style="text-align:center;">
                                  <iframe src="https://ride4kindness.swigit.com/sharethisframe3.html"
                                    style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
                                </div>
                              </div>
                          </div>
                          <!-- Details Content End -->
                      </div>
                      <!-- Col End -->
                  </div>
                  <!-- row End -->
              </div>
              <!-- Container End -->
          </section>
          <section>
            <div class=" text_actors_i spacing_top" style="text-align: center;">
              


            </div>
          </section>
          <!-- Play Details Section End -->
          <!-- Start Related Movies Section -->
         
          <!-- Related Movies Section End -->
      </div>
    </div>
      <!-- Main Content End -->
      <!-- Start Footer Section -->
      
      <!-- Footer Section End -->
      <!-- Start Copyrights Section -->
      
      <!-- Copyrights Section End -->
      <!-- To Top Button Start-->
      <!--div class="back-to-top-btn">
          <div class="back-to-top" style="display: block;">
              <a aria-hidden="true" class="fas fa-angle-up" href="#"></a>
          </div>
      </div-->
      <!-- To Top Button End -->
  
  <!-- Main Class End -->
 
  <!-- Start Filter Sidebar -->
  
  <!-- Filter Sidebar End -->
    
  </div>
  <div style="width: 100%;" *ngIf="layoutNine">

     
  
    <!-- Start Mobile Header -->
    <header class="mobile-header">
        <div class="mobile-header-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mobile-logo text-center">
                           
                            <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                              src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                        </div>
                    </div>
                    <!-- Logo Col End -->
                </div>
                <!-- Row End -->
            </div>
            <!-- Container End -->
        </div>
        <!-- Header Content End -->
    </header>
    <!-- Mobile Header End -->
    <!-- Start Header -->
    <header class="main-nav clearfix is-sticky">
        <div class="container-fluid">
            <div class="row justify-content-between" style="padding: 10px 0px;">
                <div class="col-md-3 pl-0 col-sm-12">
                    <!-- Start Navigation -->
                    <nav class="navbar navbar-expand-lg">
                        
                        <a class="navbar-brand" href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                          src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                        <!-- Logo End -->
                        
                        
                    </nav>
                    <!-- Navigation End -->
                </div>
                <div class="col-md-7 col-sm-12" >
                  <h1 class="smallH1" style="color: red !important;"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
                  <h1 class="smallH2" style="color: white !important; font-size: 14px;">
                    {{streamDetailsFull.stream_design.stream_header_tagline}}
                  </h1>
                </div>
                <!-- Col End -->
                <div class="col-md-2 text-right ">
                  <div class="topBar_menu">
                    <div style="text-align: center; "><a href="https://swigit.com/" target="_blank"><img
                          src="https://swigappmanager.com/uploads/stream_images/swigit-logo3.png" style="width: 20rem;"></a>
                    </div>
                    <div *ngIf="loginButtonActive" class="topBar_menu--item">
                      <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                      </div>
                      <!-- <div class="topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                      </div> -->
                      <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                        REGISTER
                      </div>
      
                      <div class=" topBar_menu--item1 cursor-pointer"  *ngIf="!isUserLogin" (click)="openSupportModal()">SUPPORT
                      </div>
                    </div>
      
                    <div class="btn-group " dropdown *ngIf="isUserLogin" style="width: 100%;     text-align: center; padding-top: 3px;">
                      <div class=" dropdown-toggle" style=" width: 100%;     text-align: center;" id="button-autoclose1" dropdownToggle
                        aria-controls="dropdown-autoclose1">
                        <i class="fa fa-user-circle fa-2x cursor-pointer" style="color: red;" aria-hidden="true"></i>
                      </div>
                      <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-autoclose1">
                        <!--li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                          <span class="dropdown-item">
                            My Profile
                          </span>
                        </li-->
                        <!--li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                          <span class="dropdown-item">
                            Payment History
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Swigits
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Purchases
                          </span>
                        </li-->
                        <li role="menuitem" class="cursor-pointer" (click)="openSupportModal()">
                          <span class="dropdown-item">
                            Support
                          </span>
                        </li>
                        <!-- <li role="menuitem" class="cursor-pointer">
                                                    <span class="dropdown-item" (click)="resendTicketCode()">
                                                        Re-send Code
                                                    </span>
                                                </li> -->
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                          <span class="dropdown-item" (click)="onLogout()">
                            Logout
                          </span>
                        </li>
                      </ul>
                    </div>
                    <!-- div *ngIf="isUserLogin" >
               <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;"
                 (click)="resendTicketCode()" *ngIf="resendButtonActive">
                 
                 <span class="hidden-sm"> RE-SEND CODE </span>
               </div>
             </div -->
                  </div>
                </div>
                <!-- Col End -->
            </div>
            <!-- Row End -->
        </div>
        <!-- Container End -->
    </header>
    <!-- Header End -->
    
    <!-- Start Main Content -->
    <div class="main-content" style="background: url({{streamDetailsFull.stream_design.content_background_image_url}}); background-repeat: no-repeat;
    background-size: 100% 100%; padding: 0px; ">
    <div style="background-color: rgb(52 58 64 / 70%); ">
        <!-- Start Play Details Section -->
        <section class="play-details">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-12"  style="padding-left: 0px !important; padding-right: 0px !important;">
                                <div class="play-thumb mb-4">
                                    <!--img class="img-fluid" src="{{streamDetailsFull.stream_design.poster_image_url}}" alt=""-->
                                    <div *ngFor="let image of _albums; let i=index">
                                      <img class="img-fluid" [src]="image.src" (click)="open(i)" />
                                    </div>
                                </div>
                               
                            </div>
                            <div class="col-md-12"  style="padding-left: 0px !important; padding-right: 0px !important;">
                              <div class="hidden-sm counter_class" *ngIf="eventCounterShow"
                                style="{{streamDetailsFull.stream_design.counter_style}} width: 100%; display: block;  padding-top:5px; padding-bottom: 10px">

                                {{secondsToTime(timeDiff)}}
                              </div>
                            </div>
                            <!--div class="col-md-12"  style="padding-left: 0px !important; padding-right: 0px !important;">
                              <div class="" style="width: 100%; display: block;  padding-top:5px; padding-bottom: 10px; text-align: center;">

                                <br>
                                  <a href="https://buy.stripe.com/eVa8xjbPb5d34ZWbII" target="_blank"><img src="assets/please_donate.png" style="width: 70% ;"></a>
                              </div>
                            </div-->
                            <!-- Col End -->
                        </div>
                        <!-- Row End -->
                    </div>
                    <!-- Col End -->
                    <div class="col-md-9">
                        <div class="play-details-content">
                          <div class="title-block d-flex align-items-center justify-content-between">
                            <h3 class="play-title" style="font-size: 24px !important; margin-top: 0px !important; margin-bottom: 0px !important;">
                              
                              IT'S FREE BUT PLEASE DONATE IF YOU CAN</h3>
                        </div>
                            <div class="title-block d-flex align-items-center justify-content-between">
                                <h2 class="play-title">{{streamDetailsFull.stream_title}}</h2>
                            </div>
                            <!-- Title Block -->
                            <div class="details-info mb-4">
                                <span *ngIf="streamDetailsFull.stream_certi"><i class="icofont-user mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_certi}}</span>
                                <span *ngIf="streamDetailsFull.stream_dur_str"><i class="icofont-clock-time mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_dur_str}}</span>
                                <span *ngIf="streamDetailsFull.stream_year"><i class="icofont-simple-smile mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_year}}</span>
                                <span *ngIf="streamDetailsFull.stream_genre"><i class="icofont-movie mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_genre}}</span>
                                
                            </div>
                            <!-- Details Info -->
                            <div class="details-desc">
                                <p [innerHTML]="streamDescFull"  style="margin-bottom: 5px !important;"></p>
                            </div>
                            
                            
                            <!-- Movie Persons -->
                            <div class="details-buttons">
                                <div class="row d-flex align-items-center">
                                    <div class="col-md-4 col-xl mb-xl-0 mb-3 col-sm-12">
                                      
                                        <a  (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                          <i class="icofont-ui-movie mr-2" aria-hidden="true"></i>PROMO</a>
                                    </div>
                                    <!-- Col End -->
                                   
                                    <!-- Col End -->
                                    <div class="col-md-4 col-xl mb-xl-0 mb-3 col-sm-12" style="text-align: center;">
                                     
                                      <a (click)="onWatchWithTCClickFree()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                        <i class="icofont-ui-play mr-2" aria-hidden="true"></i>WATCH HERE AT SHOWTIME</a>

                                    </div>
                                    <div class="col-md-4 col-xl mb-xl-0 mb-3 col-sm-12"  style="text-align: center;">
                                      
                                      <!--a  (click)="onDonatePerViewClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                        <i class="icofont-bill mr-2" aria-hidden="true"></i>BUY DONATE TO VIEW TICKET*</a-->

                                        <a href="https://buy.stripe.com/eVa8xjbPb5d34ZWbII" target="_blank"><img src="assets/please_donate.png" style="width: 50% ;"></a>
                                        
                                    </div>
                                    <!-- Col End -->
                                   
                                </div>

                                
                                <!-- Row End -->
                            </div>
                            <!-- Details Buttons End -->
                            <div class="details-desc" style="text-align: center;">
                              
                              <h2 style="padding-top: 20px; font-family: 'Roboto', sans-serif !important; color: white !important; font-weight: bold;" class="h2_class" >Share on social networks</h2>
                              <!--social icons-->
                              <div class="content_position" style="text-align:center;">
                                <iframe src="https://letmehelp.swigit.com/sharethisframe3.html"
                                  style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
                              </div>
                            </div>
                            <div class="details-desc" >
                              <p [innerHTML]="streamDescShort" style="font-size: 18px !important;"></p>
                            </div>

                        </div>
                        <!-- Details Content End -->
                    </div>
                    <!-- Col End -->
                </div>
                <!-- row End -->
            </div>
            <!-- Container End -->
        </section>
        <section>
          <div class=" text_actors_i " style="color: white; text-align: center;">
            *Funds generated from this Donate-To-View event are directed to 'Let Me Help', 
            a 501 (c)(3)Tax Exempt, Nonprofit Charitable Foundation.


          </div>
        </section>
        <!-- Play Details Section End -->
        <!-- Start Related Movies Section -->
       
        <!-- Related Movies Section End -->
    </div>
  </div>
    <!-- Main Content End -->
    <!-- Start Footer Section -->
    
    <!-- Footer Section End -->
    <!-- Start Copyrights Section -->
    
    <!-- Copyrights Section End -->
    <!-- To Top Button Start-->
    <!--div class="back-to-top-btn">
        <div class="back-to-top" style="display: block;">
            <a aria-hidden="true" class="fas fa-angle-up" href="#"></a>
        </div>
    </div-->
    <!-- To Top Button End -->

<!-- Main Class End -->

<!-- Start Filter Sidebar -->

<!-- Filter Sidebar End -->
  
</div>
<div style="width: 100%;" *ngIf="layoutTen">

     
  
  <!-- Start Mobile Header -->
  <header class="mobile-header">
      <div class="mobile-header-content">
          <div class="container">
              <div class="row">
                  <div class="col-md-12">
                      <div class="mobile-logo text-center">
                         
                          <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                            src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                      </div>
                  </div>
                  <!-- Logo Col End -->
              </div>
              <!-- Row End -->
          </div>
          <!-- Container End -->
      </div>
      <!-- Header Content End -->
  </header>
  <!-- Mobile Header End -->
  <!-- Start Header -->
  <header class="main-nav clearfix is-sticky">
      <div class="container-fluid">
          <div class="row justify-content-between" style="padding: 10px 0px;">
              <div class="col-md-3 pl-0  col-sm-12">
                  <!-- Start Navigation -->
                  <nav class="navbar navbar-expand-lg">
                    
                      <a class="navbar-brand" href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                        src="{{streamDetailsFull.stream_logo}}" class="img-fluid" style="width: 100%;"></a>
                      <!-- Logo End -->
                      
                      
                  </nav>
                  <!-- Navigation End -->
              </div>
              <div class="col-md-7  col-sm-12" >
                <h1 class="smallH1" style="color: red !important;"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
                <h1 class="smallH2" style="color: white !important; font-size: 14px;">
                  {{streamDetailsFull.stream_design.stream_header_tagline}}
                </h1>
              </div>
              <!-- Col End -->
              <div class="col-md-2 text-right  col-sm-12">
                <div class="topBar_menu">
                  <div style="text-align: center; "><a href="https://swigit.com/" target="_blank"><img
                        src="https://swigappmanager.com/uploads/stream_images/swigit-logo3.png" style="width: 20rem;"></a>
                  </div>
                  <div *ngIf="loginButtonActive" class="topBar_menu--item">
                    <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                    </div>
                    <!-- <div class="topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                    </div> -->
                    <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                      REGISTER
                    </div>
    
                    <div class=" topBar_menu--item1 cursor-pointer"  *ngIf="!isUserLogin" (click)="openSupportModal()">SUPPORT
                    </div>
                  </div>
    
                  <div class="btn-group " dropdown *ngIf="isUserLogin" style="width: 100%;     text-align: center; padding-top: 3px;">
                    <div class=" dropdown-toggle" style=" width: 100%;     text-align: center;" id="button-autoclose1" dropdownToggle
                      aria-controls="dropdown-autoclose1">
                      <i class="fa fa-user-circle fa-2x cursor-pointer" style="color: red;" aria-hidden="true"></i>
                    </div>
                    <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                      aria-labelledby="button-autoclose1">
                      <!--li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                        <span class="dropdown-item">
                          My Profile
                        </span>
                      </li-->
                      <!--li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                        <span class="dropdown-item">
                          Payment History
                        </span>
                      </li>
                      <li role="menuitem" class="cursor-pointer">
                        <span class="dropdown-item">
                          My Swigits
                        </span>
                      </li>
                      <li role="menuitem" class="cursor-pointer">
                        <span class="dropdown-item">
                          My Purchases
                        </span>
                      </li-->
                      <li role="menuitem" class="cursor-pointer" (click)="openSupportModal()">
                        <span class="dropdown-item">
                          Support
                        </span>
                      </li>
                      <!-- <li role="menuitem" class="cursor-pointer">
                                                  <span class="dropdown-item" (click)="resendTicketCode()">
                                                      Re-send Code
                                                  </span>
                                              </li> -->
                      <li class="divider dropdown-divider"></li>
                      <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                        <span class="dropdown-item" (click)="onLogout()">
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                  <!-- div *ngIf="isUserLogin" >
             <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;"
               (click)="resendTicketCode()" *ngIf="resendButtonActive">
               
               <span class="hidden-sm"> RE-SEND CODE </span>
             </div>
           </div -->
                </div>
              </div>
              <!-- Col End -->
          </div>
          <!-- Row End -->
      </div>
      <!-- Container End -->
  </header>
  <!-- Header End -->
  
  <!-- Start Main Content -->
  <div class="main-content" style="background: url({{streamDetailsFull.stream_design.content_background_image_url}}); background-repeat: no-repeat;
  background-size: 100% 100%; padding: 0px; ">
  <div style="background-color: rgb(52 58 64 / 78%); ">
      <!-- Start Play Details Section -->
      <section class="play-details">
          <div class="container-fluid">
              <div class="row">
                  <div class="col-md-3  col-sm-12">
                      <div class="row">
                          <div class="col-md-12" style="padding-left: 0px !important; padding-right: 0px !important;">
                              <div class="play-thumb mb-4">
                                  <img class="img-fluid" src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="">
                                  
                              </div>
                             
                          </div>
                          <!-- Col End -->
                      </div>
                      <!-- Row End -->
                  </div>
                  <!-- Col End -->
                  <div class="col-md-9  col-sm-12">
                      <div class="play-details-content">
                          <div class="details-buttons">
                          <div class="row d-flex align-items-center">




                              <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                
                                  <a  (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)" *ngIf="!promoLinkActive"
                                    class="btn d-block hvr-sweep-to-right" tabindex="0">
                                    <i class="icofont-video mr-2" aria-hidden="true"  style="font-size: 25px;"></i>{{streamDetailsFull.stream_design.promo_btn_text}}</a>
                                  <a  (click)="openLink()" *ngIf="promoLinkActive"
                                    class="btn d-block hvr-sweep-to-right" tabindex="0">
                                    <i class="icofont-video mr-2" aria-hidden="true"  style="font-size: 25px;"></i>{{streamDetailsFull.stream_design.promo_btn_text}}</a>
                              </div>
                              <!-- Col End -->
                              <div class="col-md-3 col-xl  mb-3 col-sm-12">
                               
                                <a (click)="onSYVButtonClick('L')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                  <i class="icofont-share mr-2" aria-hidden="true"  style="font-size: 25px;"></i>SHARE YOUR VOICE</a>

                              </div>
                              
                             
                              <div class="col-md-3 col-xl mb-3  col-sm-12">
                                
                                <!--a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                  <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a-->

                                <a (click)="onChannelButtonClick()" class="btn d-block hvr-sweep-to-right" tabindex="0" *ngIf="channelButtonActive">
                                    <i class="icofont-video-alt mr-2" aria-hidden="true"  style="font-size: 25px;"></i>{{streamDetailsFull.stream_design.channel_video_btn_text}}</a>
                                  
                              </div>
                              
                             
                          </div>
                          </div>
                          <div class="title-block d-flex align-items-center justify-content-between">
                              <h2 class="play-title">{{streamDetailsFull.stream_title}}</h2>
                          </div>
                          <!-- Title Block -->
                          <div class="details-info mb-4">
                              <span *ngIf="streamDetailsFull.stream_certi"><i class="icofont-user mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_certi}}</span>
                              <span *ngIf="streamDetailsFull.stream_dur_str"><i class="icofont-clock-time mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_dur_str}}</span>
                              <span *ngIf="streamDetailsFull.stream_year"><i class="icofont-simple-smile mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_year}}</span>
                              <span *ngIf="streamDetailsFull.stream_genre"><i class="icofont-movie mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_genre}}</span>
                              
                          </div>
                          <!-- Details Info -->
                          <div class="details-desc">
                              <p [innerHTML]="streamDescFull"></p>
                          </div>
                          
                          
                          <!-- Movie Persons -->
                          <div class="details-buttons">
                              
                           

                              <div class="row d-flex align-items-center">
                                <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                 
                  
                                  <a (click)="openLinkAll('https://www.chords2cure.org/donate-us')" class="btn d-block hvr-sweep-to-right" 
                                    tabindex="0">
                                    <i class="icofont-money-bag mr-2" aria-hidden="true" style="font-size: 25px;"></i>DONATE</a>
                              </div>
                                <div class="col-md-3 col-xl  mb-3 col-sm-12" >
                                   
                    
                                  <a (click)="openLinkAll('https://c2ctv.swigit.com/app/c2ctvlive')"  class="btn d-block hvr-sweep-to-right" tabindex="0">
                                    <i class="icofont-info-circle mr-2" aria-hidden="true" style="font-size: 25px;"></i>HOW TO SHARE YOUR VOICE</a>
                                  

                                </div>
                                <!-- Col End -->
                                <div class="col-md-3 col-xl  mb-3 col-sm-12  d-none d-sm-block">
                                  <a (click)="openLinkAll('https://www.chords2cure.org/c2c-merch')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                    <i class="icofont-jacket mr-2" aria-hidden="true" style="font-size: 25px;"></i>MERCH & MORE</a>
                                </div>
                                <!-- Col End -->
                                <div class="col-md-3 col-xl  mb-3 col-sm-12">
                                 
                                  <a (click)="openLinkAll('https://www.chords2cure.org/')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                    <i class="icofont-holding-hands mr-2" style="font-size: 25px;" aria-hidden="true"></i>FOUNDATION</a>

                                </div>

                                
                                
                            </div>
                              <!-- Row End -->
                          </div>

                          
                          <!-- Details Buttons End -->
                          <div class="details-desc" style="text-align: center;">
                            
                            <h2 style="padding-top: 20px; font-family: 'Roboto', sans-serif !important; color: white !important; font-weight: bold;" class="h2_class" >Share on social networks</h2>
                            <!--social icons-->
                            <div class="content_position" style="text-align:center;">
                              <iframe src="https://ride4kindness.swigit.com/sharethisframe3.html"
                                style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
                            </div>
                          </div>

                          <!--div  style="text-align: center;">

                            <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                              [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                          </div-->
                      </div>
                      <!-- Details Content End -->
                  </div>
                  <!-- Col End -->
              </div>
              <!-- row End -->
          </div>
          <!-- Container End -->
      </section>
      <section>
        <div class=" text_actors_i spacing_top" style="text-align: center;">
          


        </div>
      </section>
      <!-- Play Details Section End -->
      <!-- Start Related Movies Section -->
     
      <!-- Related Movies Section End -->
  </div>
</div>
  <!-- Main Content End -->
  <!-- Start Footer Section -->
  
  <!-- Footer Section End -->
  <!-- Start Copyrights Section -->
  
  <!-- Copyrights Section End -->
  <!-- To Top Button Start-->
  <!--div class="back-to-top-btn">
      <div class="back-to-top" style="display: block;">
          <a aria-hidden="true" class="fas fa-angle-up" href="#"></a>
      </div>
  </div-->
  <!-- To Top Button End -->

<!-- Main Class End -->

<!-- Start Filter Sidebar -->

<!-- Filter Sidebar End -->

</div>
  <div style="width: 100%;" *ngIf="layoutEleven">

      
    
    <!-- Start Mobile Header -->
    <header class="mobile-header">
        <div class="mobile-header-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mobile-logo text-center">
                          
                            <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                              src="{{streamDetailsFull.stream_logo}}" class="img-fluid" style="height: 120px !important;"></a>
                        </div>
                    </div>
                    <!-- Logo Col End -->
                </div>
                <!-- Row End -->
            </div>
            <!-- Container End -->
        </div>
        <!-- Header Content End -->
    </header>
    <!-- Mobile Header End -->
    <!-- Start Header -->
    <header class="main-nav clearfix is-sticky">
        <div class="container-fluid">
            <div class="row justify-content-between" style="padding: 10px 0px;">
                <div class="col-md-3 pl-0  col-sm-12">
                    <!-- Start Navigation -->
                    <nav class="navbar navbar-expand-lg">
                        
                        <a class="navbar-brand" href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                          src="{{streamDetailsFull.stream_logo}}" class="img-fluid" style="height: 120px !important;"></a>
                        <!-- Logo End -->
                        
                        
                    </nav>
                    <!-- Navigation End -->
                </div>
                <div class="col-md-7  col-sm-12" >
                  <img src="https://swigappmanager.com/images/angel_header.png" style="width: 90%;">
                  <!--h1 class="smallH1" style="color: red !important;"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
                  <h1 class="smallH2" style="color: white !important; font-size: 14px;">
                    {{streamDetailsFull.stream_design.stream_header_tagline}}
                  </h1-->
                </div>
                <!-- Col End -->
                <div class="col-md-2 text-right  col-sm-12">
                  <div class="topBar_menu">
                    <div style="text-align: center; "><a href="https://swigit.com/" target="_blank"><img
                          src="https://swigappmanager.com/uploads/stream_images/swigit-logo3.png" style="width: 20rem;"></a>
                    </div>
                    <div *ngIf="loginButtonActive" class="topBar_menu--item">
                      <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                      </div>
                      <!-- <div class="topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                      </div> -->
                      <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                        REGISTER
                      </div>
      
                      <div class=" topBar_menu--item1 cursor-pointer"  *ngIf="!isUserLogin" (click)="openSupportModal()">SUPPORT
                      </div>
                    </div>
      
                    <div class="btn-group " dropdown *ngIf="isUserLogin" style="width: 100%;     text-align: center; padding-top: 3px;">
                      <div class=" dropdown-toggle" style=" width: 100%;     text-align: center;" id="button-autoclose1" dropdownToggle
                        aria-controls="dropdown-autoclose1">
                        <i class="fa fa-user-circle fa-2x cursor-pointer" style="color: red;" aria-hidden="true"></i>
                      </div>
                      <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-autoclose1">
                        <!--li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                          <span class="dropdown-item">
                            My Profile
                          </span>
                        </li-->
                        <!--li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                          <span class="dropdown-item">
                            Payment History
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Swigits
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Purchases
                          </span>
                        </li-->
                        <li role="menuitem" class="cursor-pointer" (click)="openSupportModal()">
                          <span class="dropdown-item">
                            Support
                          </span>
                        </li>
                        <!-- <li role="menuitem" class="cursor-pointer">
                                                    <span class="dropdown-item" (click)="resendTicketCode()">
                                                        Re-send Code
                                                    </span>
                                                </li> -->
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                          <span class="dropdown-item" (click)="onLogout()">
                            Logout
                          </span>
                        </li>
                      </ul>
                    </div>
                    <!-- div *ngIf="isUserLogin" >
              <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;"
                (click)="resendTicketCode()" *ngIf="resendButtonActive">
                
                <span class="hidden-sm"> RE-SEND CODE </span>
              </div>
            </div -->
                  </div>
                </div>
                <!-- Col End -->
            </div>
            <!-- Row End -->
        </div>
        <!-- Container End -->
    </header>
    <!-- Header End -->
    
    <!-- Start Main Content -->
    <div class="main-content" style="background: url({{streamDetailsFull.stream_design.content_background_image_url}}); background-repeat: no-repeat;
    background-size: 100% 100%; padding: 0px; ">
    <div style="background-color: rgb(52 58 64 / 78%); ">
        <!-- Start Play Details Section -->
        <section class="play-details">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-3  col-sm-12">
                        <div class="row">
                            <div class="col-md-12" style="padding-left: 0px !important; padding-right: 0px !important;">
                                <div class="play-thumb mb-4">
                                    <img class="img-fluid" src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="">
                                    
                                </div>
                              
                            </div>
                            <div class="col-md-12" style="font-weight: bold; font-size: 1.4rem; padding: 10px !important; text-align: center; background-color: white;">
                              Angel Force USA is a non-profit charity organization. <br>
                              Our goal is to raise awareness of the military suicide epidemic.
                            </div>
                            <!-- Col End -->
                        </div>
                        <!-- Row End -->

                       
                    </div>
                    <!-- Col End -->
                    <div class="col-md-9  col-sm-12">
                        <div class="play-details-content">
                            <!--div class="title-block d-flex align-items-center justify-content-between">
                                <h2 class="play-title">{{streamDetailsFull.stream_title}}</h2>
                            </div-->
                            <!-- Title Block -->
                            <div class="details-info mb-4">
                                <span *ngIf="streamDetailsFull.stream_certi"><i class="icofont-user mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_certi}}</span>
                                <span *ngIf="streamDetailsFull.stream_dur_str"><i class="icofont-clock-time mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_dur_str}}</span>
                                <span *ngIf="streamDetailsFull.stream_year"><i class="icofont-simple-smile mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_year}}</span>
                                <span *ngIf="streamDetailsFull.stream_genre"><i class="icofont-movie mr-2" aria-hidden="true"></i> {{streamDetailsFull.stream_genre}}</span>
                                
                            </div>
                            
                            
                            <div class="details-info mb-4">
                              <img src="https://swigappmanager.com/images/afb-schedule.png" width="100%">
                            </div>
                            <!-- Details Info -->
                            <div class="details-desc" style="text-align: center;">
                              <p [innerHTML]="streamDescFull" style="font-size: 2.5rem !important;"></p>
                            </div>
                            <!-- Movie Persons -->
                            <div class="details-buttons">
                                <div class="row d-flex align-items-center">
                                  <div class="col-md-6 col-xl  mb-3 col-sm-12">
                                
                  
                                    <a  class="btn d-block hvr-sweep-to-right" tabindex="0"  (click)="event3Click()" >
                                      TUESDAY, DECEMBER 6TH, 2022 <br>
                                      10:30 AM to 4:30 PM PST
                                    </a>
                                  </div>
                                  <div class="col-md-6 col-xl  mb-3 col-sm-12 ">
                                    
                                    <a  class="btn d-block hvr-sweep-to-right" tabindex="0"  (click)="event2Click()">
                                      WEDNESDAY, DECEMBER 7TH, 2022 <br>
                                      9:00 AM to 3:00 PM PST
                                    </a>
  
                                  </div>
                                  
                                </div>
                                <div class="row d-flex align-items-center">
                                  <!-- Col End -->
                                  <div class="col-md-6 col-xl  mb-3 col-sm-12">
                                    
                      
                                    <a  class="btn d-block hvr-sweep-to-right" tabindex="0" (click)="event1Click()">
                                      TUESDAY, DECEMBER 6TH, 2022<br>
                                      VETERANS CONCERT  <br>
                                      5:30 PM to 8:30 PM PST
                                    </a>
                                  </div>
                                  <!-- Col End -->
                                  <div class="col-md-6 col-xl  mb-3 col-sm-12">
                                  
                                    <a  class="btn d-block hvr-sweep-to-right" tabindex="0"  (click)="event4Click()">
                                      WEDNESDAY, DECEMBER 7TH, 2022  <br>
                                      "NEVER FORGET" GALA  <br>
                                      6:00 PM to 9:00 PM PST
                                    </a>
  
                                  </div>

                                </div>
                            </div>
                            <div class="details-desc" style="text-align: center;">
                              <p style="font-size: 2.5rem !important;">
                                PLEASE JOIN OUR LIVE STREAMING <BR>
                                ALL EVENTS & CONCERT ARE FREE TO WATCH AT THE SCHEDULED TIMES
                              </p>
                            </div>
                            <div class="details-buttons">    
                                <div class="row d-flex align-items-center">
                                  
                                    <div class="col-md-4  mb-3 col-sm-12">
                                      
                                        <a  class="btn d-block hvr-sweep-to-right" tabindex="0"  (click)="onVideoLinkClick('https://vimeo.com/776747479/c1313f788b', 'V')">
                                        
                                          <i class="icofont-ui-movie mr-2" aria-hidden="true"></i>PROMO</a>
                                    </div>
                                    <!-- Col End -->
                                    <div class="col-md-4  mb-3 col-sm-12">
                                    
                                      <a  class="btn d-block hvr-sweep-to-right" tabindex="0" (click)="onWatchWithTCC()">
                                        <i class="icofont-ui-play mr-2" aria-hidden="true"></i>WATCH</a>

                                    </div>
                                    <div class="col-md-4  mb-3  col-sm-12">
                                      
                                      <!--a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                        <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a-->

                                      <a (click)="openLinkAll('https://www.efundraisingconnections.com/c/AngleForceUSA')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                                          <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a>
                                        
                                    </div>
                                    
                                    <!--div class="col-md-3 col-xl  mb-3 col-sm-12 d-none d-sm-block">
                                    &nbsp;
                                    </div-->
                                    
                                    
                                  
                                </div>

                               
                                <!-- Row End -->
                            </div>

                            <div>&nbsp;<br>&nbsp;&nbsp;<br>&nbsp;&nbsp;<br>&nbsp;</div>
                            <!-- Details Buttons End -->
                            <!--
                            <div class="details-desc" style="text-align: center;">
                              <div class="row d-flex align-items-center">
                                <div class="col-md-2 col-xl  mb-3 col-sm-12">  
                                  <a  class="btn d-block hvr-sweep-to-right" tabindex="0">
                                    Participants & Sponsorship <br> Information
                                  </a>
                                </div>
                                <div class="col-md-7 col-xl  mb-3 col-sm-12">  
                                  <p style="padding-top: 20px; color: white !important; font-weight: 400;font-size: 2.5rem !important;" class="" 
                                  >PLEASE POST THIS EVENT ON SOCIAL MEDIA</p>
                                 
                                  <div class="content_position" style="text-align:center;">
                                    <iframe src="https://angelforce.swigit.com/sharethisframe3.html"
                                      style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
                                  </div>
                                </div>
                                <div class="col-md-4 col-xl  mb-3 col-sm-12">  
                                  Live Stream By: 
                                  <img src="https://swigappmanager.com/images/cymtv-logo.png" style="width: 49%">
                                  <img src="https://swigappmanager.com/images/swigit-logo.png" style="width: 49%;">
                                </div>
                              </div>
                            </div>
                            -->
                        </div>
                        <!-- Details Content End -->
                    </div>
                    <!-- Col End -->
                </div>
                <!-- row End -->
            </div>
            <!-- Container End -->
        </section>
        
        <!-- Play Details Section End -->
        <!-- Start Related Movies Section -->
      
        <!-- Related Movies Section End -->
    </div>
  </div>
    <!-- Main Content End -->
    <!-- Start Footer Section -->
    
    <!-- Footer Section End -->
    <!-- Start Copyrights Section -->
    
    <!-- Copyrights Section End -->
    <!-- To Top Button Start-->
    <!--div class="back-to-top-btn">
        <div class="back-to-top" style="display: block;">
            <a aria-hidden="true" class="fas fa-angle-up" href="#"></a>
        </div>
    </div-->
    <!-- To Top Button End -->

  <!-- Main Class End -->

  <!-- Start Filter Sidebar -->

  <!-- Filter Sidebar End || layoutEleven -->

  </div>
  <div style="width: 100%;" *ngIf="layoutTwelve">

    <link href="/assets/css/happy_styles.css" rel="stylesheet"> 
  
    <!-- Start Mobile Header -->
    <header class="mobile-header">
        <div class="mobile-header-content">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mobile-logo text-center">
                           
                            <a href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                              src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                        </div>
                    </div>
                    <!-- Logo Col End -->
                </div>
                <!-- Row End -->
            </div>
            <!-- Container End -->
        </div>
        <!-- Header Content End -->
    </header>
    <!-- Mobile Header End -->
    <!-- Start Header -->
    <header class="main-nav clearfix is-sticky">
        <div class="container-fluid">
            <div class="row justify-content-between" style="padding: 10px 0px;">
                <div class="col-md-3 pl-0 col-sm-12">
                    <!-- Start Navigation -->
                    <nav class="navbar navbar-expand-lg">
                        
                        <a class="navbar-brand" href="{{streamDetailsFull.stream_logo_url}}#" target="_blank"> <img
                          src="{{streamDetailsFull.stream_logo}}" class="img-fluid"></a>
                        <!-- Logo End -->
                        
                        
                    </nav>
                    <!-- Navigation End -->
                </div>
                <div class="col-md-7 col-sm-12" >
                  <h1 class="smallH1" style="font-size: 5rem !important;font-weight: 500 !important; color: #9ed4ed !important;"> {{streamDetailsFull.stream_design.streamHeader}}</h1>
                  <h1 class="smallH2" style="color: white !important; font-size: 14px;">
                    {{streamDetailsFull.stream_design.stream_header_tagline}}
                  </h1>
                </div>
                <!-- Col End -->
                <div class="col-md-2 text-right ">
                  <div class="topBar_menu">
                    <div style="text-align: center; "><a href="https://swigit.com/" target="_blank"><img
                          src="https://swigappmanager.com/uploads/stream_images/swigit-logo3.png" style="width: 20rem;"></a>
                    </div>
                    <div *ngIf="loginButtonActive" class="topBar_menu--item">
                      <div class="topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openLoginModal()">LOGIN
                      </div>
                      <!-- <div class="topBar_menu--item1 cursor-pointer" *ngIf="isUserLogin" (click)="onLogout()">LOGOUT
                      </div> -->
                      <div class="  topBar_menu--item1 cursor-pointer" *ngIf="!isUserLogin" (click)="openRegisterModal()">
                        REGISTER
                      </div>
      
                      <div class=" topBar_menu--item1 cursor-pointer"  *ngIf="!isUserLogin" (click)="openSupportModal()">SUPPORT
                      </div>
                    </div>
      
                    <div class="btn-group " dropdown *ngIf="isUserLogin" style="width: 100%;     text-align: center; padding-top: 3px;">
                      <div class=" dropdown-toggle" style=" width: 100%;     text-align: center;" id="button-autoclose1" dropdownToggle
                        aria-controls="dropdown-autoclose1">
                        <i class="fa fa-user-circle fa-2x cursor-pointer" style="color: red;" aria-hidden="true"></i>
                      </div>
                      <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                        aria-labelledby="button-autoclose1">
                        <!--li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                          <span class="dropdown-item">
                            My Profile
                          </span>
                        </li-->
                        <!--li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                          <span class="dropdown-item">
                            Payment History
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Swigits
                          </span>
                        </li>
                        <li role="menuitem" class="cursor-pointer">
                          <span class="dropdown-item">
                            My Purchases
                          </span>
                        </li-->
                        <li role="menuitem" class="cursor-pointer" (click)="openSupportModal()">
                          <span class="dropdown-item">
                            Support
                          </span>
                        </li>
                        <!-- <li role="menuitem" class="cursor-pointer">
                                                    <span class="dropdown-item" (click)="resendTicketCode()">
                                                        Re-send Code
                                                    </span>
                                                </li> -->
                        <li class="divider dropdown-divider"></li>
                        <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                          <span class="dropdown-item" (click)="onLogout()">
                            Logout
                          </span>
                        </li>
                      </ul>
                    </div>
                    <!-- div *ngIf="isUserLogin" >
               <div class="pt-2 pb-2 topBar_menu--item2" style="cursor: pointer;"
                 (click)="resendTicketCode()" *ngIf="resendButtonActive">
                 
                 <span class="hidden-sm"> RE-SEND CODE </span>
               </div>
             </div -->
                  </div>
                </div>
                <!-- Col End -->
            </div>
            <!-- Row End -->
        </div>
        <!-- Container End -->
    </header>
    <!-- Header End -->
    <section class="bodyForSite" style="background: url({{streamDetailsFull.stream_design.content_background_image_url}}); background-repeat: no-repeat;
    background-size: cover; background-position: center ; padding: 0px; ">

      <!--wrapper-->
      <div class="contentWrapper">
          <!--image-->
          <div class="bodyForSite_image">
              <img src="{{streamDetailsFull.stream_design.poster_image_url}}" alt="movie poster" class="bodyForSite--poster">
          </div>

          <!--information-->
          <div class="bodyForSite_information" style="background-color: transparent  !important;">

              <!--description-->
              <div class="content--information">
                  <div class="border--content">
                      <p>
                          As we celebrate our progress made in 2022 in funding many more deserving charities, we ask you to please consider investing in our work to aid people less fortunate and less healthy, with a year-end gift.
                      </p>
                     
                      <p>
                          There has never been a more important time to be part of the solution to the challenges we face as a nation and world.  Your 100% tax-deductible donation will help support the people nationally and of the world with a bias towards children, our hope for the future.
                      </p>
                      
                      <p>
                          Looking ahead, our Let Me Help Foundation plans for 2023 will again focus on charity concerts both live and online and other programs, to not only raise funds but also to amplify problems through media and artists and inspire people to help and take action where possible as it is needed.
                      </p>
                      <br>
                    <span style="font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 1;
                    text-transform: none;">
                          Charities we have supported and will continue to support, together with others that need our help:
                          <ul style="padding-left: 40px; margin-top: 10px; list-style-type: disc !important; line-height: 1.5 !important;">
                              <li style=" list-style-type: disc !important;">The American Cancer Society</li>
                              <li style=" list-style-type: disc !important;">Chords2Cure</li>
                              <li style=" list-style-type: disc !important;">Creative Visions</li>
                              <li style=" list-style-type: disc !important;">Kids of Ukraine</li>
                              <li style=" list-style-type: disc !important;">Meals on Wheels (with a focus on COVID-a9 relief)</li>
                              <li style=" list-style-type: disc !important;">Operation Warrior Shield</li>
                              <li style=" list-style-type: disc !important;">The Prostate Cancer Society</li>
                              <li style=" list-style-type: disc !important;">Rock and Roll for Children</li>
                              <li style=" list-style-type: disc !important;">Share and Care</li>
                          </ul>
                        </span>
                      <p style="margin-top: 10px;">
                          In Founder John “Sohojohnny” Pasquale's own words, Let Me Help Foundation's Mission Statement is “To spread music, joy and illumination to the human soul and spirit while helping others.”
                      </p>
                     
                      <p style="font-size: 10px !important; margin-top: 10px;">
                          Let Me Help Foundation is a 501c3 nonprofit charity.
                      </p>
                      <!--QR code-->
                      <div class="QRcode">
                          <img src="https://swigappmanager.com/uploads/stream_images/happyh_bells.webp" alt="QRcode" class="QR">
                      </div>
                  </div>
              </div>

              <!--social network sharing-->
              <div class="content--information_social">
                  <div class="content--information2">
                    
                                    
                      <a  class="btn d-block hvr-sweep-to-right" style="padding: 20px 40px !important; font-size: 17px; border-radius: 5px; " tabindex="0"
                      (click)="onPromoBtnClick(streamDetailsFull.stream_trailer_url)">
                        <i class="icofont-ui-play mr-2" aria-hidden="true"></i>WATCH</a>

                    
                      <div class="content_position2" style="padding-top: 10px;">
                          <!--img src="https://swigappmanager.com/uploads/stream_images/donate.webp" class="donate_img"-->
                          <a href="{{streamDetailsFull.stream_design.extra_video_btn_url}}"target="_blank"><img src="assets/please_donate.png"  class="donate_img" ></a>
                                        
                      </div>
                  </div>
                  <p style="margin: 1rem;">
                      Donation Transactions are collected by Swig Media and 100% of these funds are forwarded to the Let Me Help Foundation, which is a 501c3 nonprofit charity.
                  </p>
                  <div class="row">
                    <div class="col-md-12   col-sm-12">
                      <h2>
                          Share on social network
                      </h2>
                    </div>
                    <div class="col-md-12   col-sm-12 content_position2">
                      <iframe src="https://letmehelp.swigit.com/sharethisframe4.html"
                      style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
                    </div>
                  </div>

                  
              </div>
          </div>
      </div>
  </section>
    <!-- Start Main Content -->
   
    <!-- Main Content End -->
    <!-- Start Footer Section -->
    
    <!-- Footer Section End -->
    <!-- Start Copyrights Section -->
    
    <!-- Copyrights Section End -->
    <!-- To Top Button Start-->
    <!--div class="back-to-top-btn">
        <div class="back-to-top" style="display: block;">
            <a aria-hidden="true" class="fas fa-angle-up" href="#"></a>
        </div>
    </div-->
    <!-- To Top Button End -->

<!-- Main Class End -->

<!-- Start Filter Sidebar -->

<!-- Filter Sidebar End -->
  
</div>
  </div>
  <section class="pupular"  *ngIf="layoutEight || layoutNine || layoutTen || layoutTwelve ">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <h2 class="block-title" *ngIf="layoutEight || layoutNine || layoutTen  ">Performances</h2>
                <h2 class="block-title" *ngIf="layoutTwelve">PARTICIPATING FOUNDATIONS</h2>
                <div class="owl-carousel owl-theme" id="pupular-slider">
                  <div class="item"  *ngFor="let links of this.streamDetailsFull.stream_links;">
                      <div class="video-block" *ngIf="links.link_type === 'IL'"    >
                          <div  class="video-thumb position-relative thumb-overlay">
                              <a ><img alt=""   class="img-fluid" src="{{links.link_image_path}}"></a>
                              <div class="box-content" *ngIf="links.link_url">
                                <ul class="icon">
                                   
                                    <li>
                                        <a href="{{links.link_url}}" target="_blank"><i class="fas fa-info"></i></a>
                                    </li>
                                </ul>
                            </div>
                          </div>
                      </div>
                      <div class="video-block" *ngIf="links.link_type === 'VL'"  >
                        <div  class="video-thumb position-relative thumb-overlay">
                            <a ><img alt=""   class="img-fluid" src="{{links.link_image_path}}"></a>
                            <div class="box-content" *ngIf="links.link_url">
                              <ul class="icon" style="height: 100px !important;">
                                  <li>
                                      <a (click)="onVideoLinkClick(links.link_url, links.video_type)" style="cursor: pointer;" ><i class="fas fa-play"></i>Play</a>
                                  </li>
                                  
                              </ul>
                            </div>
                            <a href="#" style="color: white; font-size: 17px; font-weight: bold; padding-top: 15px;">{{links.stream_title}}</a>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
        </div>
    </div>
</section>

<section class="pupular"  *ngIf=" layoutEleven " style="">
<div class="row">
  <div class="col-md-2  mb-3  col-sm-12">
                                      
    <!--a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
      <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a-->

    <a (click)="openLinkAll('https://swigappmanager.com/images/angelforce-pdf.pdf')" class="btn d-block hvr-sweep-to-right" tabindex="0">
        Participants & Sponsorship <br> information</a>
      
  </div>
  
  <div class="col-md-8   col-sm-12">  
    <p style="color: white !important; font-weight: 400;font-size: 2.5rem !important; text-align: center;" class="" 
    >PLEASE POST THIS EVENT ON SOCIAL MEDIA</p>
    <!--social icons-->
    <div class="content_position" style="text-align:center;">
      <iframe src="https://angelforce.swigit.com/sharethisframe3.html"
        style="border: none; overflow: hidden; text-align: center;" width="85%" height="90px"></iframe>
    </div>
  </div>
  <div class="col-md-2  col-sm-12"> 
    <div class="row">
      <div class="col-md-12  col-sm-12" style="text-align: center; color: white; font-size: 1.7rem;"> 
        Live Streaming by:     
      </div>
    </div>
    <div class="row">
      <div class="col-md-6  col-sm-12" style="padding-top: 10px;">      
        
        <a href="https://cymtv.world" target="_blank"><img src="https://swigappmanager.com/images/cymtv-logo.png"></a>
    
      </div>
      <div class="col-md-6  col-sm-12" style="padding-top: 0px;">     
        
        <a href="https://swigit.com/" target="_blank"><img src="https://swigappmanager.com/images/swigit-logo.png"></a>
      

      </div>

    </div>
  </div>
  
  
</div>
</section>



<div class="siteFooter" style="border-top: none !important;" 
  *ngIf="layoutThree || layoutEight || layoutNine || layoutTen || layoutEleven || layoutTwelve" >
  <!-- copyright information -->
  <div class="col-md-2 col-sm-12" >
    <div class="row">
            <div class="col-lg-12 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center; ">
                <!--SWIGIT™ Patent Pending
            <br>
                <a href="https://swigit.com/"
                    target="_blank">swigit.com</a>-->
                    <!--
                    <qrcode [qrdata]="myAngularxQrCode" [width]="150" [errorCorrectionLevel]="'M'"
                    [colorLight]="'#d9d9d9'" [cssClass]="'QR'"></qrcode>
                    -->
                                      
                      <!--a (click)="alertMessageClick()" class="btn d-block hvr-sweep-to-right" tabindex="0">
                        <i class="icofont-bill mr-2" aria-hidden="true"></i>DONATE</a-->

                      <a (click)="onVideoLinkClick('https://vimeo.com/776748324/97b02f080c', 'V')" class="btn d-block hvr-sweep-to-right" tabindex="0">
                          What is a SWIGIT?</a>
                        
                    
            <br>

                info@swigmedia.com
            </div>
    </div>
  </div>
  <div class="col-md-6 col-sm-12" >
    <div class="row">
    <div class="col-lg-12 col-sm-12 footer_big"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center;  ">
                SWIGIT... Bringing you 'One-Click-To-Watch' entertainment you like, <Br>
                  wherever  you are.
           
            </div>
          </div>
  </div> 
  <div class="col-md-4 col-sm-12" style="border: 1px solid red; background-color: red;">
    <div class="row">
    <div class="col-lg-12 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white; text-align: center; ">
                <a *ngIf="!isUserLogin" (click)="openRegisterModal()" style="cursor: pointer">
                Register free for Notifications, Free-To-Watch, <br>
                Discounts and Rewards
                </a>
                <a *ngIf="isUserLogin" (click)="openRegisterMessageModal()" style="cursor: pointer">
                  Register free for Notifications, Free-To-Watch, <br>
                  Discounts and Rewards
                </a>
            </div>
          </div>
  </div>
  
</div>


<div class="main-footer2" style="background-color: #272829;" *ngIf="layoutEight">
  
      <div class="row justify-content-lg-between justify-content-center">
        
      
        <!-- how to watch on TV -->
        <div  class="siteFooter_TV col-md-12 col-sm-12" style="padding: 0px !important;">
          <div class="row">
            
            <div class="col-lg-12 col-sm-12 " style="padding: 0px !important;">
              <div class="row siteFooter_TV--watch" style="padding: 0px !important;">
                <div class="" style="width: 50%;">
                
                        <p 
                          style="text-align: right !important; padding-right: 15px; padding-top: 5px; padding-left: 10px">Check out our
                          <br>
                          Augmented Reality<br>
                          Promotion!<br>
                          
                          <span style="font-size: 2rem;">SCAN <i class="fa fa-arrow-right" aria-hidden="true"></i></span><br>
                          <a href="https://arurl.co/q4yr9v" target="_blank" style="color: white;">https://arurl.co/q4yr9v</a>
                          
                        </p>
                </div>
                <div class=" " style="width: 50%;">
                        <a href="https://arurl.co/q4yr9v" target="_blank"><img  src="https://swigappmanager.com/uploads/stream_images/ride-qr.png"
                            style="height: 130px; padding: 10px 10px; width: auto !important; max-width: none !important;"></a>


                </div>
              </div>
            </div>
          </div>           
        </div>
          
      </div>
      <!-- Row End -->
  
  <!-- Container End -->
</div>
                
  <app-spinner *ngIf="showLoading">
  </app-spinner>
</div>
<div *ngIf="!streamCorrect" style="background-color: black;"><img src="{{contentBackgroundStyle}}" width="90%">
</div>