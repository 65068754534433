<div class="modal-content" style="padding-top: 15px;">

    <div class="modal-body">
        <div class="card p-4 promo">
            <div class="card-body pt-0" id="register">
                <div *ngIf="!errorMessage">
                   
                    
                    <br>
                    <form [formGroup]="registerFormGroup" autocomplete="off" >
                        <!-- <div *ngIf="!forgetPasswordMode">   *ngIf="!isSignUpSucc" -->
                        <div class="form-group">
                            <label class="PromoMsg mb-1" for="inputNewsletter">Register free for 
                                Notifications, <br>Free-To-Watch, Discounts and Rewards  </label>
                            <input nbInput   type="checkbox" class="form-control"  (click)="onCheckbooxClick()"
                                id="inputNewsletter" formControlName="inputNewsletter">
                               
                        </div>

                       

                        <div id="login-box">
                            <!-- <nb-checkbox name="rememberMe" class="float-left">Remember me</nb-checkbox> -->
                            <!-- <nb-checkbox name="rememberMe" class="float-right">Remember me</nb-checkbox> -->
                            <div class="d-flex justify-content-center">
                                <button id="register-btn" class="btn px-4" *ngIf="enableR"
                                    (click)="onRegisterClick()">Register</button>

                                <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()" style="margin-left: 5px;">Cancel</button>
                            </div>
                        </div>

                    </form>
                </div>
                <div class="text-center" *ngIf="errorMessage">
                    <p id="error-message">
                        {{ errorMessage }}
                    </p>
                    <button id="cancel-btn" class="btn cancel-btn px-4" (click)="onCancel()" style="margin-left: 5px;">Close</button>
                </div>
                
            </div>

        </div>
    </div>