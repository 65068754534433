<div style=" padding: 5px;  " *ngIf="!isHomePage">
<div *ngIf="streamCorrect">
    <!--div style=" " *ngIf="!showLoading"-->
 
    <div id="header-panel">
        <div id="header-content">
            <div>
                <div class="" style="background-color: black; color: white;  text-align:center;  ">

                    <div class="row" style="">
                        <div class="col-lg-3 col-sm-12" style="height: 90px; float: left; text-align: center; padding-bottom: 0px !important; 
              padding-right: 15px !important; ">
                            

                            <div style=" padding: 5px;  " *ngIf="isClientPage">
                                <!--div style=" font-size: 30px;">STARS 
                IN THE HOUSE  & SWIG MEDIA </div>
                <div style=" font-size: 24px; margin-top: -10px;">presents </div-->
                                <a href="#" target="_blank"> <img src="{{appDesign.app_logo}}" height="90px"></a>
                            </div>
                            <div style=" padding: 5px;  " *ngIf="!isClientPage">
                                <!--div style=" font-size: 30px;">STARS 
                IN THE HOUSE  & SWIG MEDIA </div>
                <div style=" font-size: 24px; margin-top: -10px;">presents </div-->
                                <a href="#" target="_blank"> <img src="{{appBundlesHP.bundle_logo}}" height="90px"></a>
                            </div>

                        </div>
                        <div class="col-lg-6 col-sm-12 " style="margin: auto;{{appDesign.app_header_text_style}}"
                            *ngIf="isClientPage">
                            {{appDetails1.appName}}

                            <!--img src="../../assets/event-name-top.png" width="80%" -->
                        </div>
                        <div class="col-lg-6 col-sm-12 " style="margin: auto;{{appDesign.app_header_text_style}}"
                            *ngIf="!isClientPage">
                            {{appBundlesHP.bundle_name}}

                            <!--img src="../../assets/event-name-top.png" width="80%" -->
                        </div>
                        <div class="col-lg-3 col-sm-12 " style="float: right; text-align: center;margin: auto;">
                            <div class="logout_btn d-flex w-100 mr-3 login_box" style="justify-content: flex-end; ">


                                <div *ngIf="!isUserLogin">
                                    <button class="pt-2 pb-2 text-right login cursor-pointer"
                                        style="background-color: #1055a4" (click)="openLoginModal()"
                                        *ngIf="loginButtonActive">
                                        <i class="fa fa-sign-in" aria-hidden="true"></i>
                                        <span class="hidden-sm"> Login </span>
                                    </button>
                                    <button class="pt-2 pb-2 text-right register cursor-pointer"
                                        style="background-color:#1055a4" (click)="openRegisterModal()"
                                        *ngIf="loginButtonActive">
                                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                                        <span class="hidden-sm"> Register </span>
                                    </button>
                                    <button class="pt-2 pb-2 text-right cursor-pointer"
                                        [ngStyle]="{'background-color': isUserLogin ? '#37a7df' : '#a42310'}"
                                        (click)="openSupportModal()">
                                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                                        <span class="hidden-sm"> Support </span>
                                    </button>
                                </div>

                                <div *ngIf="isUserLogin">
                                    <!--button class="pt-2 pb-2 text-right logout"
                                    style="cursor: pointer; ;background-color:#1055a4" (click)="resendTicketCode()"
                                    *ngIf="loginButtonActive">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                    <span class="hidden-sm"> Re-send Code </span>
                                </button-->
                                    <!-- <button class="pt-2 pb-2 text-right logout cursor-pointer"
                                        style="background-color:#1055a4" (click)="onLogout()">
                                        <i class="fa fa-sign-out" aria-hidden="true"></i>
                                        <span class="hidden-sm"> Logout </span>
                                    </button> -->
                                    <button class="pt-2 pb-2 text-right cursor-pointer"
                                        [ngStyle]="{'background-color': isUserLogin ? '#37a7df' : '#a42310'}"
                                        (click)="openSupportModal()">
                                        <i class="fa fa-question-circle" aria-hidden="true"></i>
                                        <span class="hidden-sm"> Support </span>
                                    </button>
                                    <div class="btn-group ml-3" dropdown *ngIf="loginButtonActive">
                                        <div class="mr-2 dropdown-toggle" id="button-autoclose1" dropdownToggle aria-controls="dropdown-autoclose1">
                                            <i class="fa fa-user-circle fa-3x cursor-pointer" aria-hidden="true"></i>
                                        </div>
                                        <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                                            aria-labelledby="button-autoclose1">
                                            <li role="menuitem" class="cursor-pointer" (click)="onMyProfileClick()">
                                                <span class="dropdown-item">
                                                    My Profile
                                                </span>
                                            </li>
                                            <li role="menuitem" class="cursor-pointer" (click)="onMyPaymentHistoryClick()">
                                                <span class="dropdown-item">
                                                    Payment History
                                                </span>
                                            </li>
                                            <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item">
                                                    My Swigits
                                                </span>
                                            </li>
                                            <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item">
                                                    My Purchases
                                                </span>
                                            </li>
                                            <!-- <li role="menuitem" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="resendTicketCode()">
                                                    Re-send Code
                                                </span>
                                            </li> -->
                                            <li class="divider dropdown-divider"></li>
                                            <li role="menuitem" *ngIf="loginButtonActive" class="cursor-pointer">
                                                <span class="dropdown-item" (click)="onLogout()">
                                                    Logout
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!--/div-->


    <!-- <app-spinner *ngIf="showLoading">
</app-spinner> -->

</div>
<div *ngIf="!streamCorrect" style="background-color: black;"><img src="../../assets/img/splash-logo.png" width="90%">not
    found</div>
<div class="sharethis-inline-share-buttons"></div>

</div>