import { environment } from '../../environments/environment';
export class APP_URLS {
    static WEB_API_URL: string = environment.WEB_API_URL;
    static STRIPE_PAYMENT_API_URL: string = environment.STRIPE_PAYMENT_API_URL;
    static SUPPORT_API_URL: string = environment.SUPPORT_API_URL;
    static DEMO_API_URL: string = environment.DEMO_API_URL;
}



export const APP_DOMAIN = "celebration"; // "3e909131cbb1a1f308183c838bc005d7"
export const STREAM_TITLE = "celebbroadway";

/* NOT USEFUL ANYMORE - START */
export const APP_ID = "4680bb230a519ba9e7e7e54e77ee4734"; // "3e909131cbb1a1f308183c838bc005d7"
export const STREAM_GUID = "caf44f1dbedb43d0414e15981575f2c9";
export const MENU_GUID = "290c24c62de3e584c4f34f05436067d5";
export const eventStartTime = "2020-10-26T17:30:00"; // 26th Oct 5:30 PM
export const USAEventTimeZone = "America/Los_Angeles"; // PDT
export const EuropeEventTimeZone = "Europe/Paris";
export const eventEndTime = "2020-10-26T18:50:00";
/* NOT USEFUL ANYMORE - END */

export const DHARA_USERCODE = "f38b4d13a7d92093e26372bb94d17cdd";

export const IP_ADDRESS_REGEX = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;

export const LoginAction = "checkUserLoginNew";
export const RegisterFirstToWatch = "registerFirsttoWatch";

export const RegisterAction = "register";
export const AccountActivationAction = "validateAccountActivationCode";
export const dnpPaySuccessAction = "buyTicket";
export const dnpTicketGenAction = "watchWithTicketCodeForLiveEvent";
export const guestTicketGenAction = "sendGuestTicketRequest";
export const changePasswordAction = "changePassword";
export const forgotPasswordAction = "forgotPassword";

// STRIPE CREDENTIALS
// export const stripeAPIPubKey = "pk_live_OqunIPlGZJ6IVDcEzLKgi7Qj00WD5DiHig";  // LIVE PK
export const stripeAPIPubKey = "pk_test_xijGaojvj6V9Ae1adv6kKMDN";  // TEST PK

// Stripe Token for Capture Payment
// export const stripeAPIToken = "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"; // Live Token
export const stripeAPIToken = "sk_test_Lr0VtmNnhemHch6IUvauT4dI"; // Test Token

// PAYPAL CREDENTIALS
export const paypalClientID = 'AfhnWUk_O6n8p8S9lBVeXTIXID0uMdFh-b2Ynv3r867TGBf2WOi_Igq6t4rv1Kj5MwTId1reKe8DOF_l';  // LIVE CLIENT ID
//export const paypalClientID = "sb";  // TEST CLIENT ID
