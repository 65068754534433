import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { APP_URLS } from '../../providers/constant';

@Injectable({
  providedIn: 'root'
})
export class DonatePerViewService {

  private swigApiUrl = `${APP_URLS.WEB_API_URL}`;
  private stripePaymentApiUrl = `${APP_URLS.STRIPE_PAYMENT_API_URL}`;
  private supportApiUrl = `${APP_URLS.SUPPORT_API_URL}`;

  constructor(private http: HttpClient) { }

  public dpvPayment(formData: any): Observable<any> {
    return this.http.post<any>(`${this.swigApiUrl}/manageticketcode`, formData);
  }
  public dpvTicketGen(formData: any): Observable<any> {
    return this.http.post<any>(`${this.swigApiUrl}/manageticketcode`, formData);
  }
  public dpvPayforGiftTicket(formData: any): Observable<any> {
    return this.http.post<any>(`${this.swigApiUrl}/sendguestticketrequests`, formData);
  }
  public createPaymentIntents(paymentObject: any): Observable<any> {
    return this.http.post<any>(`${this.stripePaymentApiUrl}/createPaymentIntents`, paymentObject);
  }
  //public createStripeCharges(formData: any): Observable<any> {
  public createStripeCharges(paymentObject: FormData): Observable<any> {
    //return this.http.post<any>(`${this.stripePaymentApiUrl}/capturestripepayment`, paymentObject);
    return this.http.post<any>(`${this.stripePaymentApiUrl}/capturestripepaymenttest`, paymentObject);
    //return this.http.post<any>(`${this.stripePaymentApiUrl}/createStripeCharges`, paymentObject);
  }
  public generateLogs(log: any): Observable<any> {
    return this.http.post<any>(`${this.supportApiUrl}/generateLogs`, log);
  }

  public sendTicketCode(resendcodeObject: FormData): Observable<any> {
    return this.http.post<any>(`${this.stripePaymentApiUrl}/resendticketcode`, resendcodeObject);
  }

  public purchaseBundleByUser(purchaseObject: any): Observable<any> {
    return this.http.post<any>(`${this.stripePaymentApiUrl}/manage_apis/feed/v1/stripe_payment/stripe_session.php`, purchaseObject);
  }
}
