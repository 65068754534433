<div id="footer-panel">
    <div id="footer-content">
        <div style='width: 100%;   background-color:black; '
            class="row">
            <div class="col-lg-2 col-sm-12 footer_1"
                style="padding-bottom: 10px;padding-top: 10px;margin: auto; color: white;  ">
                SWIGIT™ Patent Pending
            </div>
            <div class="col-lg-2 col-sm-12 footer_2" *ngIf="eventFooterLink"
                style="padding-bottom: 10px; padding-top: 10px;margin: auto;  color: white; ">
                <a href="https://swigit.com/"
                    target="_blank">swigit.com</a>
            </div>
            <div class="col-lg-2 col-sm-12 footer_2" *ngIf="!eventFooterLink"
                style="padding-bottom: 10px;  padding-top: 10px;margin: auto; color: white; ">

                info@swigmedia.com
            </div>
            <div class="col-lg-8 col-sm-12 footer_3"
                style=" text-align: right; padding-bottom: 20px; padding-top: 20px; ">
                <a href="{{streamDetailsFull.data.appDesigns.android_app_url}}" *ngIf="androidAppAvailable"
                    target="_blank"><img src="../../assets/google-play-badge.png" height="50px;"></a>
                <a href="{{streamDetailsFull.data.appDesigns.ios_app_url}}" *ngIf="iosAppAvailable"
                    style="padding-left: 10px;" target="_blank"><img src="../../assets/apple-store-badge.png"
                        height="50px"></a>
                <a href="{{streamDetailsFull.data.appDesigns.firetv_app_url}}" *ngIf="firetvAppAvailable"
                        style="padding-left: 10px;" target="_blank"><img src="../../assets/firetv_badge.png" height="50px;"></a>
                <a href="{{streamDetailsFull.data.appDesigns.roku_app_url}}" *ngIf="rokuAppAvailable"
                        style="padding-left: 10px;" target="_blank"><img src="../../assets/roku_store.png"
                            height="50px"></a>
                <!--a href="../../assets/TV-Instructions-Celebration.pdf" download
                    style="font-family: 'Tw Cen MT Condensed'; font-size: 24px;color: white; text-shadow: 2px 2px 2px #252525;">
                    How to Watch on TV</a-->

            </div>
        </div>
    </div>
</div>