import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';

import { CommonUtilsService } from '../shared/services/common-utils.service';
import { FetchJsonService } from '../shared/services/fetch-json.service';
import { AuthRequest, LoginService, AuthResponse } from './login.service';
import { AppStorage, MessageFor } from '../providers/enum';
import { LoginAction } from '../providers/constant';
import { RegisterComponent } from '../register/register.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { StorageService } from '../shared/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //#region variables

  @ViewChild('loginTemplate') public loginTemplate: ModalDirective;

  public loginMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public loginFormGroup: FormGroup;
  public streamDetails;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private router: Router,
    private storageService: StorageService,
    private modalService: BsModalService,
    private loginService: LoginService) { }

  ngOnInit(): void {
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.getLoginMessage();
    this.createLoginFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getLoginMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.loginMessage = messageList[MessageFor.LoginForm];
      });
  }

  private createLoginFormGroup(): void {
    this.loginFormGroup = this.formBuilder.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      activationcode: [null],
      // email: null
    }, {
      validator: [
        CommonUtilsService.checkEmail('email', true)
      ]
    });
  }

  //#endregion

  //#region public methods

  public get loginControls() { return this.loginFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onActiveClick(): void {

    this.showLoading = true;
    this.isCheckValidation = true;
    let appCode = this.storageService.get(AppStorage.appCodeLS, true);
    const formData = new FormData();
    formData.append("emailOrusername", this.loginControls.email.value);
    formData.append("accountActivationCode", this.loginControls.activationcode.value);
    //formData.append("appId", appCode);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", 'validateAccountActivationCode');
    // const authRequest: AuthRequest = {};
    // authRequest.email = this.loginControls.email.value;
    // authRequest.password = this.loginControls.password.value;
    // authRequest.appId = APP_ID;
    // authRequest.postAction = LoginAction;

    this.subs.sink = this.loginService.authenticateUser(formData)
      .subscribe(
        (authResponse: AuthResponse) => {
          if (authResponse.status) {
            this.loginService.onLogin(authResponse);

            // this.router.navigateByUrl('/live-event');
            window.location.reload();
            // this.onCancel();
          } else {
            // document.getElementById('activation-code').hidden = false;

            this.isSignUp = false;
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
        //  console.log('error: ', error);
          this.showLoading = false;
        }
      );


  }

  public onCreateAccount(): void {
    this.onCancel();
    this.modalRef = this.modalService.show(
      RegisterComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
    );
  }


  public onLoginClick(): void {
    if (this.loginFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;
    let appCode = this.storageService.get(AppStorage.appCodeLS, true);
    const formData = new FormData();
    formData.append("email", this.loginControls.email.value);
    formData.append("password", this.loginControls.password.value);
    //formData.append("appId", appCode);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", LoginAction);
    // const authRequest: AuthRequest = {};
    // authRequest.email = this.loginControls.email.value;
    // authRequest.password = this.loginControls.password.value;
    // authRequest.appId = APP_ID;
    // authRequest.postAction = LoginAction;

    this.subs.sink = this.loginService.authenticateUser(formData)
      .subscribe(
        async (authResponse: AuthResponse) => {
         // console.log('authResponse: ', authResponse);
          if (authResponse.status) {
            this.loginService.onLogin(authResponse);
            try {
              let result = await this.loginService.checkEventIsBuyed(authResponse.data.userCode).toPromise();
              if (result.app.status) {
                this.storageService.set(AppStorage.isLiveEventBuyed, result.app.all_streams.current_page_stream.is_live_event_buyed, true);
              }
            } catch (error) {
              //console.log('error: ', error);
            }

            // this.router.navigateByUrl('/live-event');
            window.location.reload();
            // this.onCancel();
          } else {
            // document.getElementById('activation-code').hidden = false;

            if (authResponse.msg != "Sorry, invalid email address or password entered.")
              this.isSignUp = false;
            this.errorMessage = authResponse.msg;
          }
          this.showLoading = false;
        },
        (error) => {
        //  console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }

  public onForgotPassword(): void {
    this.onCancel();
    this.modalRef = this.modalService.show(
      ForgotPasswordComponent,
      Object.assign({ignoreBackdropClick: true}, { class: 'gray modal-md' })
    );
  }

  //#endregion

}
