import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';

import { FetchJsonService } from '../shared/services/fetch-json.service';
import { AppStorage, MessageFor } from '../providers/enum';
import { dnpTicketGenAction, LoginAction } from '../providers/constant';
import { StorageService } from '../shared/services/storage.service';
import { DonatePerViewService } from '../donate-per-view-modal/services/donate-per-view.service';
import { LiveVideoPlayerComponent } from '../live-video-player/live-video-player.component';
import { LoginService } from '../login/login.service';
import { VimeoplayerComponent } from '../vimeoplayer/vimeoplayer.component';

@Component({
  selector: 'app-enter-ticket-code',
  templateUrl: './enter-ticket-code.component.html',
  styleUrls: ['./enter-ticket-code.component.scss']
})
export class EnterTicketCodeComponent implements OnInit, OnDestroy {

  //#region variables

  public ticketCodeMessage: any;
  public errorMessage: string;
  public showLoading = false;
  public isCheckValidation = false;
  public isSignUp: boolean = true;
  public userData: any;
  public ticketCodeFormGroup: FormGroup;
  public streamDetails;

  private subs = new SubSink();

  //#endregion

  //#region life-cycle hook

  constructor(
    private formBuilder: FormBuilder,
    private fetchJsonService: FetchJsonService,
    private modalRef: BsModalRef,
    private modalService: BsModalService,
    private loginService: LoginService,
    private storageService: StorageService,
    private donatePerViewService: DonatePerViewService) { }

  ngOnInit(): void {
    let data = this.storageService.get(AppStorage.data, true);
    this.userData = JSON.parse(data);
    let details = this.storageService.get(AppStorage.streamDetails, true);
    this.streamDetails = JSON.parse(details);
    this.getTicketCodeMessage();
    this.createTicketCodeFormGroup();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  //#endregion

  //#region private methods

  private getTicketCodeMessage(): void {

    this.subs.sink = this.fetchJsonService.getMessagesList()
      .subscribe((messageList) => {
        this.ticketCodeMessage = messageList[MessageFor.TicketCodeForm];
      });
  }

  private createTicketCodeFormGroup(): void {
    this.ticketCodeFormGroup = this.formBuilder.group({
      activationcode: [null, [Validators.required]],
    });
  }

  //#endregion

  //#region public methods

  public get ticketCodeControls() { return this.ticketCodeFormGroup.controls; }

  public onCancel(): void {
    this.modalRef.hide();
  }

  public onSubmitClick(): void {
    if (this.ticketCodeFormGroup.invalid) {
      return;
    }

    this.showLoading = true;
    this.isCheckValidation = true;

    let appCode = this.storageService.get(AppStorage.appCodeLS, true);

    const formData = new FormData();
    formData.append("userCode", this.userData.userCode);
    formData.append("ticketCode", this.ticketCodeControls.activationcode.value);
    formData.append("streamGuid", this.streamDetails[0].stream_guid);
    //formData.append("appId", appCode);
    formData.append("appId", this.streamDetails[0].app_code);
    formData.append("postAction", dnpTicketGenAction);

    this.subs.sink = this.donatePerViewService.dpvTicketGen(formData)
      .subscribe(
        async (response: any) => {
         // console.log('response: ', response);
          if (response.status) {
            this.modalRef.hide();
            try {
              let result = await this.loginService.checkEventIsBuyed(this.userData.userCode).toPromise();
              if (result.app.status) {
                this.storageService.set(AppStorage.isLiveEventBuyed, result.app.all_streams.current_page_stream.is_live_event_buyed, true);
              }
            } catch (error) {
              //console.log('error: ', error);
            }
            setTimeout(() => {
              let initialState = { url: "https://vimeo.com/644887462/a60f4eb5e4" };
              this.modalRef = this.modalService.show(
                VimeoplayerComponent,
                //PromovideoComponent,
                Object.assign({ ignoreBackdropClick: true }, { class: 'gray fullHeight modal-lg', initialState  })
              );
            /*  
              this.modalRef = this.modalService.show(
                LiveVideoPlayerComponent,
                Object.assign({ignoreBackdropClick: true}, { class: 'gray fullWidth modal-lg' })
              );*/
            }, 1000);
          } else {
            this.ticketCodeFormGroup.reset();
            this.errorMessage = response.msg;
          }
          this.showLoading = false;
        },
        (error) => {
         // console.log('error: ', error);
          this.showLoading = false;
        }
      );
  }

  //#endregion

}
