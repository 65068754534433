import { Component, Input, OnInit } from '@angular/core';

import { StreamDetailsService } from '../../services/stream-details.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  //#region variables

  @Input() streamDetailsFull: any;
  @Input() isHomePage: any;

  public iosAppAvailable: boolean = false;
  public androidAppAvailable: boolean = false;
  public eventFooterLink = true;
  public rokuAppAvailable: boolean = false;
  public firetvAppAvailable: boolean = false;
  //#endregion

  //#region life-cycle hook

  constructor() { }

  ngOnInit(): void {
    this.setStreamDetails();
  }

  //#endregion

  //#region private methods

  private async setStreamDetails() {

   // console.log("full = ", this.streamDetailsFull);
  /*  if (this.streamDetailsFull.data.stream_design.footer_link_or_text === 'L')
      this.eventFooterLink = true;
    else
      this.eventFooterLink = false;
*/console.log("lo=", this.isHomePage);
if(!this.isHomePage)
{
    if (this.streamDetailsFull.data.appDesigns.android_app_avail === 'Y')
      this.androidAppAvailable = true;
    else
      this.androidAppAvailable = false;

    if (this.streamDetailsFull.data.appDesigns.ios_app_avail === 'Y')
      this.iosAppAvailable = true;
    else
      this.iosAppAvailable = false;

    if (this.streamDetailsFull.data.appDesigns.roku_app_avail === 'Y')
      this.rokuAppAvailable = true;
    else
      this.rokuAppAvailable = false;

    if (this.streamDetailsFull.data.appDesigns.firetv_app_avail === 'Y')
      this.firetvAppAvailable = true;
    else
      this.firetvAppAvailable = false;
  }
  }
  //#endregion

}
