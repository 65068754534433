import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { StorageService } from './storage.service';
import { APP_URLS, APP_DOMAIN, STREAM_TITLE } from '../../providers/constant';
import { AppStorage } from '../../providers/enum';


@Injectable({
  providedIn: 'root'
})
export class StreamDetailsService {

  private streamDetailApiUrl = `${APP_URLS.WEB_API_URL}`;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  public getStreamDetails(): Observable<any> {

    let sitedomain = this.storageService.get(AppStorage.siteDomain, true);
    let streamTitle = this.storageService.get(AppStorage.siteTitle, true);
    let streamDetURL = this.streamDetailApiUrl + "/feed/v1/stream_detail_v2/" + sitedomain + "/" + streamTitle;
    //let streamDetURL = this.streamDetailApiUrl + "/feed/v1/stream_detail_v2/" + sitedomain + "/" + streamTitle;
    //console.log("stream url = " , streamDetURL);
    //return this.http.get<any>(this.streamDetailApiUrl + "/feed/v1/stream_detail_v2/" + sitedomain + "/" + streamTitle);

    if (sitedomain && streamTitle) {
      return this.http.get<any>(`${this.streamDetailApiUrl}/feed/v1/stream_detail_v2/${sitedomain}/${streamTitle}`);
    } else {
     // sitedomain = "celebration";
     // streamTitle = "celebbroadway";
      return this.http.get<any>(`${this.streamDetailApiUrl}/feed/v1/stream_detail_v2/${sitedomain}/${streamTitle}`);

    }
  }

  public getChanallDetails(): Observable<any> {
    
    let sitedomain = this.storageService.get(AppStorage.siteDomain, true);
    
    //console.log("no domain found ChanALL:" + sitedomain);

    let streamDetURL = this.streamDetailApiUrl + "/getAllDetails";
    //console.log(this.http.get<any>(streamDetURL));
    return this.http.get<any>(streamDetURL);
     
  }

  public getClientDetails(): Observable<any> {
    
    let sitedomain = this.storageService.get(AppStorage.siteDomain, true);
    
   // console.log("no domain found:" + sitedomain);

    if (sitedomain ) {
      let streamDetURL = this.streamDetailApiUrl + "/getbundles/" + sitedomain+"/swigits";
      return this.http.get<any>(streamDetURL);
    } else {
      
      sitedomain = "celebration";
     // streamTitle = "celebbroadway";
      let streamDetURL = this.streamDetailApiUrl + "/getbundles/" + sitedomain;
      return this.http.get<any>(streamDetURL);

    }
  }

  public getBundleDetails(): Observable<any> {
    
    let sitedomain = this.storageService.get(AppStorage.siteDomain, true);
    let sitetitle = this.storageService.get(AppStorage.siteTitle, true);
    //console.log("no domain found:"+sitedomain+"-"+sitetitle);

    if (sitetitle ) {
      let streamDetURL = this.streamDetailApiUrl + "/getbundles/" + sitedomain+"/"+sitetitle;
      return this.http.get<any>(streamDetURL);
    } else {
      sitedomain = "celebration";
      sitetitle = "celebbroadway";
      let streamDetURL = this.streamDetailApiUrl + "/getbundles/" + sitedomain+"/"+sitetitle;
      return this.http.get<any>(streamDetURL);

    }
  }

  public trackUserAccessHome(appCode): Observable<any> { 
    let trackurl = "https://swigappmanager.com/trackusers/"+appCode+"/home";
    return this.http.get<any>(trackurl);
  }

  public trackUserAccessPayment(appCode, usercode): Observable<any> { 
    let trackurl = "https://swigappmanager.com/trackusers/"+appCode+"/donation/"+usercode;
    return this.http.get<any>(trackurl);
  }

  public trackUserAccessPromo(appCode, usercode): Observable<any> { 
    let trackurl = "https://swigappmanager.com/trackusers/"+appCode+"/promo/"+usercode;
    //alert(trackurl);
    return this.http.get<any>(trackurl);
  }

  public trackUserAccessWatch(appCode): Observable<any> { 
    let trackurl = "https://swigappmanager.com/trackusers/"+appCode+"/watch";
    //alert(trackurl);
    return this.http.get<any>(trackurl);
  }

  public trackUserAccessVideo(): Observable<any> { 
  
    return this.http.get<any>(`https://swigappmanager.com/track-user-celebration.php?appCode=7f912b2a598f9397d282950787b6b9d0&accessType=play_video`);
  }

  public trackUserAccessVideoSwigit(): Observable<any> { 
  
    return this.http.get<any>(`https://swigappmanager.com/track-user-celebration.php?appCode=7f912b2a598f9397d282950787b6b9d0&accessType=play_video_flipbook`);
  }
  
}
